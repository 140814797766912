import React, { useState } from "react";
import { useFormValidation } from "../../Validations/useFormValidations";
import "../../App.css";
import { returnData, returnErrorColor, returnErrorMessage, returningValue } from "../../ConfigFiles/SmallFunctions";
import { useNavigate } from "react-router-dom";
import { notify, save } from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Consts";
import svhealth1 from "../../Images/landLogo.png";
import { useTranslation } from "react-i18next";
import { enquiry } from "../../ConfigFiles/ValidationSchemas";
import loginImg from "../../Images/landImg.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { genderList } from "../../ConfigFiles/ConstList";

const PatientRegistration = () => {
  const navigate = useNavigate();

  const [isRtl, setIsRtl] = useState(false);
  const { t, i18n } = useTranslation();

  const submit = async () => {
    let res = await save(urls?.user?.saveEnquiryUser, data);
    if (res?.data?.status == true) {
      navigate("/");
    }
  };

  const { data, errors, formChange, handleSubmit, handleEmailChange } = useFormValidation({
    initialValues: {},
    validationSchema: enquiry,
    submit: submit,
  });

  return (
    <>
      {/* <button onClick={handleAddToHomeScreenClick}>Add to Home Screen</button> */}
      <div className="d-flex align-items-center justify-content-around mainLogin bg-white" style={{ height: "100vh" }}>
        <div className="col-md-6 col-xl-6  d-flex justify-content-center ">
          <form className="loginCard " onSubmit={handleSubmit}>
            <div className=" gap- p-4  d-flex flex-column    " style={{ borderRadius: "30px" }}>
              <div className="">
                <div className=" text-center fs-3 fw-bold text-primary ">
                  <img src={svhealth1} className="" height={100} />
                </div>
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="  ">
                  <label className="text-size">
                    Name<span className="text-danger">*</span>
                  </label>
                  <input type="text" name="name" value={returnData(data, "name")} onChange={formChange("name", "Alphabet")} placeholder="Name" className={`form-control p-2 ${returnErrorColor(errors?.name)}`} />
                  <small className="text-danger">{returnErrorMessage(errors?.name)}</small>

                </div>

                <div className=" ">
                  <label className="text-size">
                    Mobile<span className="text-danger">*</span>
                  </label>
                  <input type="text" name="mobile"
                    maxLength={15}
                    value={returningValue(data?.["mobile"], "Phone")}
                    onChange={formChange("mobile", "Phone")}

                    placeholder="Mobile" className={`form-control p-2     ${returnErrorColor(errors?.mobile)}`} />
                  {/* <input maxLength={"10"} type="text" name="mobile" value={returnData(data, "mobile")} onChange={formChange("mobile", "NumberString")} placeholder="Mobile" className={`form-control p-2 ${returnErrorColor(errors?.mobile)}`} /> */}
                  <small className="text-danger">{returnErrorMessage(errors?.mobile)}</small>

                </div>

                <div className="">
                  <label className="text-size">
                    Email<span className="text-danger">*</span>
                  </label>
                  <input type="text" name="email" value={returnData(data, "email")} onChange={handleEmailChange("email")} placeholder="Email" className={`form-control p-2 ${returnErrorColor(errors?.email)}`} />
                  <small className="text-danger">{returnErrorMessage(errors?.email)}</small>

                </div>

                <div className="">
                  <label className="text-size">Gender</label>
                  <select type="text" name="gender" value={returnData(data, "gender")} onChange={formChange("gender")} placeholder="Gender" className={`form-control p-2 ${returnErrorColor(errors?.gender)}`}>
                    <option value={""}>Select</option>
                    {genderList?.map((e, i) => (
                      <option key={i}>{e}</option>
                    ))}
                  </select>
                </div>

                <div className="  ">
                  <label className="text-size">
                    Query<span className="text-danger">*</span>
                  </label>
                  <textarea type="text" name="query" value={returnData(data, "query")} onChange={formChange("query", "AlphaNumaric")} placeholder="Query" className={`form-control p-2 ${returnErrorColor(errors?.query)}`} />
                  <small className="text-danger">{returnErrorMessage(errors?.query)}</small>

                </div>
                {/* <div className="d-flex mr_25">{errors && errors.password && <p className=" text-danger">{errors.password}</p>}</div> */}
                <div className=" d-flex flex-column justify-content-center align-items-center" style={{ marginTop: "10px" }}>
                  <button className=" rounded p-2 w-50   addbtn" type="submit">
                    Enquiry
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-6  ImageContainer">
          <img src={loginImg} style={{ height: "60vh", width: "80%" }} />
        </div>
      </div>
    </>
  );
};

export default PatientRegistration;
