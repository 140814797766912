import React, { FunctionComponent, useEffect, useState } from "react";
import { useLifecycles } from "react-use";
import { loadScriptFn } from "./Utils";
import { JitsiMeeting } from "@jitsi/react-sdk";
import "./jitsi.css"

// interface JitsiProps {
//   roomName: string;
//   displayName: string;
//   onEnd: () => void;
// }
const JitsiMeet = ({ roomName, displayName, onEnd }) => {
  // const [jitsi, setJitsi] = (useState < JitsiInstance) | (null > null);
  const [jitsi, setJitsi] = useState(null);
  // const load = () => loadScriptFn("https://meet.jit.si/external_api.js", true);
  // const createMeet = () =>
  //   new window.JitsiMeetExternalAPI("meet.jit.si", {

  //     parentNode: document.getElementById("jitsi-root"),
  //     roomName,

  //     userInfo: {
  //       displayName,
  //     },
  //     interfaceConfigOverwrite: {
  //       SHOW_JITSI_WATERMARK: false,
  //       SHOW_WATERMARK_FOR_GUESTS: false,
  //       DISPLAY_WELCOME_PAGE_CONTENT: false,
  //       SHOW_CHROME_EXTENSION_BANNER: false,
  //       SHOW_POWERED_BY: false,
  //       SHOW_PROMOTIONAL_CLOSE_PAGE: true,
  //     },
  //     configOverwrite: {
  //       disableSimulcast: false,
  //       enableClosePage: true,
  //     },

  //   });
console.log(onEnd,"sdfsfwer")
  const createMeet = () => {
    return (
      <JitsiMeeting
      // onApiReady={()}
        onReadyToClose={() => onEnd()}
        parentNode={document.getElementById("jitsi-root")}
        roomName={roomName}
        userInfo={{ displayName: displayName }}
        interfaceConfigOverwrite={{
          SHOW_JITSI_WATERMARK: false,
          SHOW_WATERMARK_FOR_GUESTS: false,
          DISPLAY_WELCOME_PAGE_CONTENT: false,
          SHOW_CHROME_EXTENSION_BANNER: false,
          SHOW_POWERED_BY: false,
          SHOW_PROMOTIONAL_CLOSE_PAGE: true,
        }}
        configOverwrite={{
          disableSimulcast: false,
          enableClosePage: true,
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "90vh";
        }}
      />
    );
  };

  const initialize = async () => {
    // await load();
    // setJitsi(createMeet());
  };
  // // const destroy = () => jitsi?.dispose();
  const destroy = () => {
    onEnd();
    // jitsi?.dispose()
    // window.location.pathname = "/v1/practice/appointment/dashboard";
  };
  // useLifecycles(initialize, destroy);
  useEffect(() => {
    initialize();
    // Cleanup function to call destroy when the component unmounts
    return () => {
      destroy();
    };
  }, []);

  return (
    <div className="border border-danger" id="jitsi-root " style={{ width: "100%" }}>
      {createMeet()}
    </div>
  );
};
export default JitsiMeet;