import { lazy, Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LocalRender from "./Components/Localization/Components/LocalRender";
import Localization from "./Components/Localization/Components/Localization";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AROHSPrivateRoute from "./Components/PrivateRoutes/AROHSPrivateRoute";
import BookAppointment from "./Components/Auth/BookAppointment";
import CarePlans from "./Components/Auth/CarePlans";
import CustomerCare from "./Components/Auth/CusomerCare";
import BookLabAppointment from "./Components/Auth/BookLabAppointment";
import SearchForAppointment from "./Components/Auth/SearchForAppointment";
import LandingPage from "./Components/Auth/LandingPage";
import "./App.css";
import MirrorFly from "./ThirdPartyIntegrations/MirrorFly/Listeners/MirrorFly";
import { sendWhatsappMessage } from "./ThirdPartyIntegrations/Whatsapp/Whatsapp";
import { mobileDetect } from "./ConfigFiles/SmallFunctions";
import RecurringAppointmentfromLanding from "./Components/Auth/RecurringAppointmentfromLanding";
import Offers from "./Components/Auth/Offers";
import JitsiVideo from "./ThirdPartyIntegrations/Jitsi/JitsiVideo";
import PatientRegistration from "./Components/Auth/PatientRegistration";
import { AiOutlineMessage } from "react-icons/ai";
import ChatBot from "./Components/ChatBot/ChatBot";

const RoutingConfig = lazy(() => import("./Components/RoutingConfig"));
const Login = lazy(() => import("./Components/Auth/Login"));
const CreatePassword = lazy(() => import("./Components/Auth/CreatePassword"));
const Credentialing = lazy(() => import("./Components/Credentialing/CredentialiniRC"));
const EHR = lazy(() => import("./Components/EHR/EHRRC"));
const Patientportal = lazy(() => import("./Components/PatientPortal/PatientPortal"));
const AppointmentDetails = lazy(() => import("./Components/PracticeManagement/Main/Models/AppointmentDetails"));
const AppointmentFeedback = lazy(() => import("./Components/PracticeManagement/Main/Models/AppointmentFeedBack"));
const Razorpay = lazy(() => import("./ThirdPartyIntegrations/Razorpay/OpenRaz"));
const PatientConsentForm = lazy(() => import("./Components/PracticeManagement/Main/Appointments/PatientConcentForm2"));
const AddPatient = lazy(() => import("./Components/PracticeManagement/Masters/Patient/AddPatient"));

const App = () => {
  const [isRtl, setIsRtl] = useState(false);
  const { t, i18n } = useTranslation();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const changeLanguage = (lng) => {
    lng == "Arabic" ? setIsRtl(true) : setIsRtl(false);
    i18n.changeLanguage(lng);
  };

  const handleParentClick = () => setIsChatbotOpen(false)
  useEffect(() => {
    // sendWhatsappMessage("9160488347", "Hey hi here");
  }, []);

  return (
    // <Raz amount={100}/>
    <div style={{ direction: isRtl ? "rtl" : "ltr" }}>
      <div onClick={handleParentClick}>
        <Suspense fallback={() => <div>Loading ....</div>}>
          <MirrorFly />

          <Router>
            <Routes>
              {/* <Route path="/" element={mobileDetect()?.isMobile ? <Login /> : <LandingPage />} /> */}
              <Route path="/" element={<LandingPage />} />
              <Route path="patientportal/*" element={<Patientportal />} />
              <Route path="/bookappointment" element={<BookAppointment />}>
                <Route path=":specialityId" element={<BookAppointment />} />
              </Route>
              <Route path="/careplans" element={<CarePlans />}></Route>
              <Route path="/customercare" element={<CustomerCare />}></Route>
              <Route path="offers" element={<Offers />} />
              <Route path="/enquiry" element={<PatientRegistration />} />
              <Route path="/searchforappointment" element={<SearchForAppointment />} />
              <Route path="/booklabappointment" element={<BookLabAppointment />} />
              <Route path="/recurringappointment" element={<RecurringAppointmentfromLanding />} />
              <Route path="/appointmentDetails/:id" element={<AppointmentDetails />} />
              <Route path="/feedback/:id" element={<AppointmentFeedback />} />
              <Route path="/payment/:appointmentId/:amount" element={<Razorpay />} />
              <Route path="patientconsentform/:appointmentId" element={<PatientConsentForm />} />

              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Login />} />
              <Route path="/createpassword/:token" element={<CreatePassword />} />
              <Route path="jitsi/:displayName/:roomName" element={<JitsiVideo />} />
              <Route path="/careplans" element={<CarePlans />}></Route>
              <Route path="/register" element={<AddPatient />}></Route>

              <Route path="*" element={<Login />} />
              {/* <Route path="/" element={<Twilio />} /> */}
              {/* <Route path="/" element={<VideoApp />} /> */}
              {/* <Route path="v1/*" element={<AROHSPrivateRoute> <RoutingConfig /> </AROHSPrivateRoute>} /> */}
              <Route
                path="v1/*"
                element={
                  <AROHSPrivateRoute>
                    <RoutingConfig rtl={isRtl} />
                  </AROHSPrivateRoute>
                }
              />
              <Route
                path="cred/*"
                element={
                  <AROHSPrivateRoute>
                    <Credentialing />
                  </AROHSPrivateRoute>
                }
              />
              <Route
                path="ehr/*"
                element={
                  <AROHSPrivateRoute>
                    <EHR />
                  </AROHSPrivateRoute>
                }
              />
            </Routes>
          </Router>
        </Suspense>
      </div>
      <div className="chatbot-button ">
        <AiOutlineMessage
          onClick={() => setIsChatbotOpen(!isChatbotOpen)}
          size={30}
        />
        {isChatbotOpen && <ChatBot />}
      </div>
    </div>
  );
};

export default App;
