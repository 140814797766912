import React, { useEffect, useState } from "react";

const LabAppointmentDetails = ({ labTests, labPackages, close, closeAndUpdate, data, selectedLabPackage }) => {

  // const [selectedLabPackage, setSelectedPackage] = useState(labPackages?.filter((v) => v?.labPackageId == data?.labPackageId)?.[0]);
  console.log(selectedLabPackage)
  return (
    <div className="border h-100 d-flex align-items-center justify-content-center" >
      <div className="border d-flex flex-column w-100 bg bg-white rounded">
        <div className="border p-3 d-flex   fw-semibold fs-4">
          <div className="text-center">Lab Package Details </div>
        </div>
        <div className="row p-2 m-2  -lg">
          <div className="col-md-12 mb-2">
            <div className="fw-bold">How {selectedLabPackage?.labPackageName} Package helps you?</div>
            <label className="text-size">
              {" "}
              Being healthy is the key to being happy, and the importance of screening tests for our health cannot be neglected. A Good<span> {selectedLabPackage?.labPackageName} </span> is tailored to identify the health risks early so that preventive steps can be taken in advance, as timely screening helps ensure the right treatment at the right time, which gives you better control over your health. This package offers tests to evaluate your cholesterol levels, thyroid function, and average blood sugar levels over the past 8 to 12 weeks. This package helps in the evaluation of your overall health.
            </label>
            <div className="fw-bold">Package Tests Included in Good Health Smart Package</div>
            {/* {JSON.stringify(selectedLabPackage)} */}
          </div>
          <div>
            {labTests
              ?.filter((v) => selectedLabPackage?.labTestIds?.split(",")?.some((a) => parseInt(a) == v?.labTestId))
              ?.map((e, i) => (
                <div key={i}>
                  {e?.labTestName}
                  {i}
                </div>
              ))}
          </div>
        </div>
        <div className="border col-md-12  justify-content-end d-flex gap-2 p-3 ">
          <button className="cancelbtn px-3 py-1" onClick={close}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default LabAppointmentDetails;
