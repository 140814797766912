export const BookingAppointment = {
  firstName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  }, lastName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  }, mobile: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
    minlength: {
      value: 10
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  preferredDate: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  dob: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  preferredDate: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  teleConsultation: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  appointmentName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  // nationalAddress: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  // city: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  gender: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  // cluster: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  // neighbourhoodDistrict: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // }
}
export const RecuringAppointment = {
  firstName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  }, lastName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
    minlength: {
      value: 10
    },
  },

  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },


  dob: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  dates: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },

  teleConsultation: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  appointmentName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  //  nationalAddress: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  // city: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  gender: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  // cluster: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  // neighbourhoodDistrict: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // }
}

export const BooklabAppointment = {
  firstName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  }, lastName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  requestedDateTime: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
    minlength: {
      value: 10
    },
  },

  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },


  dob: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },

  // nationalAddress: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  // city: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  gender: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  // cluster: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // },
  // neighbourhoodDistrict: {
  //   required: {
  //     value: true,
  //     message: "Please Enter First Name",
  //   },
  // }
}
export const patient = {
  firstName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Please Enter Last Name",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter Mobile",
    },
  },
  dob: {
    required: {
      value: true,
      message: "Please Enter date",
    },
  },
  // nationalId: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // nationalAddress: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  //  city: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // cluster: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // neighbourhodDistric: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // phc: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // dateOfBirth: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // gender: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // maritalStatus: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // uniqueFamilyIdentifier: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // employeementStatus: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // empType: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
  // insuranceCompany: {
  //   required: {
  //     value: true,
  //     message: "Please Enter Mobile",
  //   },
  // },
};

export const patientRelative = {
  relativeName: {
    required: {
      value: true,
      message: "Please Enter Relative Name",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter Mobile number",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
};

export const patientAddress = {
  nationalAddress: {
    required: {
      value: true,
      message: "Please Enter nationalAddress",
    },
  },
  cluster: {
    required: {
      value: true,
      message: "Please Enter cluster",
    },
  },
  city: {
    required: {
      value: true,
      message: "Please Enter city",
    },
  },
  neighbourhoodDistrict: {
    required: {
      value: true,
      message: "Please Enter neighbourhodDistrict",
    },
  },
  pinCode: {
    required: {
      value: true,
      message: "Please Enter pinCode",
    },
  },
};

export const enquiry = {
  name: {
    required: {
      value: true,
      message: "Please Enter Name",
    },
  },
  query: {
    required: {
      value: true,
      message: "Please Enter Query",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter Mobile",
    },
  },
};
export const login = {
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  password: {
    required: {
      value: true,
      message: "Please Enter Password",
    },
  },
};

export const speciality = {
  specialityName: {
    required: {
      value: true,
      message: "Please Enter Speciality",
    },
  },
  color: {
    required: {
      value: true,
      message: "Please select color",
    },
  },
};
export const createPassword = {
  password: {
    required: {
      value: true,
      message: "Please Enter password",
    },
  },
  confirmPassword: {
    required: {
      value: true,
      message: "Please Enter confirm password",
    },
  },
};

export const ward = {
  wardName: {
    required: {
      value: true,
      message: "Please Enter Ward Name",
    },
  },
  wardType: {
    required: {
      value: true,
      message: "Please select Ward type",
    },
  },
};

export const hospital = {
  hospitalName: {
    required: {
      value: true,
      message: "Please Enter Ward Name",
    },
  },
  code: {
    required: {
      value: true,
      message: "Please enter code",
    },
  },
  branch: {
    required: {
      value: true,
      message: "Please enter branch",
    },
  },
};
export const specialist = {
  specialityId: {
    required: {
      value: true,
      message: "Please Enter Ward Name",
    },
  },
  specialistTypeName: {
    required: {
      value: true,
      message: "Please enter code",
    },
  },
};

export const hospitalAdmin = {
  firstName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Please Enter Last Name",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Please Select gender",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Email",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter Mobile",
    },
  },
  hospitalId: {
    required: {
      value: true,
      message: "Please Select Hospital",
    },
  },
};

export const bed = {
  bedName: {
    required: {
      value: true,
      message: "Please Enter Bed Name",
    },
  },
  wardId: {
    required: {
      value: true,
      message: "Please select Ward",
    },
  },
  bedPrice: {
    required: {
      value: true,
      message: "Please Enter Bed Price"
    }
  }
};
export const room = {
  roomName: {
    required: {
      value: true,
      message: "Please Enter Room Name",
    },
  },
  roomType: {
    required: {
      value: true,
      message: "Please select Room type",
    },
  },
};

export const updatingInPatient = {
  procedureId: {
    required: {
      value: true,
      message: "Please Enter Room Name",
    },
  },
  admittedDate: {
    required: {
      value: true,
      message: "Please select Room type",
    },
  },
  physicianId: {
    required: {
      value: true,
      message: "Please select Room type",
    },
  },
}

export const updatePassword = {
  currentPassword: {
    required: {
      value: true,
      message: "Please Enter current password",
    },
  },
  newPassword: {
    required: {
      value: true,
      message: "Please Enter new password",
    },
  },
  confirmPassword: {
    required: {
      value: true,
      message: "Please Enter confirm password",
    },
  },
};

export const editProfile = {
  firstName: {
    required: {
      value: true,
      message: "Please Enter first name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Please Enter last name",
    },
  },
  gender: {
    required: {
      value: true,
      message: "Please select gender",
    },
  },
  dob: {
    required: {
      value: true,
      message: "Please select DOB",
    },
  },
};
export const packages = {
  packageName: {
    required: {
      value: true,
      message: "Please Enter first name",
    },
  },
  charges: {
    required: {
      value: true,
      message: "Please Enter last name",
    },
  },
  // description: {
  //   required: {
  //     value: true,
  //     message: "Please select gender",
  //   },
  // },
};

export const services = {
  serviceName: {
    required: {
      value: true,
      message: "Please Enter first name",
    },
  },
  specialityId: {
    required: {
      value: true,
      message: "Please Enter last name",
    },
  },
  charge: {
    required: {
      value: true,
      message: "Please select gender",
    },
  },

  // medicineIds:{
  //   required: {
  //     value: true,
  //     message: "Please select gender",
  //   },
  // },
  // description: {
  //   required: {
  //     value: true,
  //     message: "Please select gender",
  //   },
  // },
};
export const procedures = {
  procedureName: {
    required: {
      value: true,
      message: "Please Enter first name",
    },
  },
  specialityId: {
    required: {
      value: true,
      message: "Please Enter last name",
    },
  },
  cost: {
    required: {
      value: true,
      message: "Please select gender",
    },
  }
}
export const vehile = {
  name: {
    required: {
      value: true,
      message: "Please Enter first name",
    },
  },
  regNumber: {
    required: {
      value: true,
      message: "Please Enter last name",
    },
  },
  fitnessValidityDate: {
    required: {
      value: true,
      message: "Please select gender",
    },
  },
  leaseStartDate: {
    required: {
      value: true,
      message: "Please select DOB",
    },
  },
  leaseEndDate: {
    required: {
      value: true,
      message: "Please select DOB",
    },
  },
  // description: {
  //   required: {
  //     value: true,
  //     message: "Please select DOB",
  //   },
  // },
};
export const driver = {
  driverName: {
    required: {
      value: true,
      message: "Please Enter first name",
    },
  },
  drivingLicenceNumber: {
    required: {
      value: true,
      message: "Please Enter last name",
    },
  },
  validityDate: {
    required: {
      value: true,
      message: "Please select gender",
    },
  },
  liecenceType: {
    required: {
      value: true,
      message: "Please select DOB",
    },
  },
};

export const physician = {
  firstname: {
    required: {
      value: true,
      message: "Please Enter Bed Name",
    },
  },
  firstname: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  lastName: {
    required: {
      value: true,
      message: "Please Enter First Name",
    },
  },
  email: {
    required: {
      value: true,
      message: "Please Enter Valid Email",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter Valid Mobile Number",
    },
  },
  specialityId: {
    required: {
      value: true,
      message: "Please Select Speciality",
    },
  },
  specialistTypeId: {
    required: {
      value: true,
      message: "Please Select Speciality",
    },
  },
};

export const formPack = {
  formPackName: {
    required: {
      value: true,
      message: "Please Enter FormPack Name",
    },
  },
  charges: {
    required: {
      value: true,
      message: "Please Enter FormPack Name",
    },
  },
  formName: {
    required: {
      value: true,
      message: "Please Enter Form Name",
    },
    minlength: {
      value: 1,
      message: "Please select atleast one",
    },
  },
};
export const lab = {
  labName: {
    required: {
      value: true,
      message: "Please Enter Lab Name",
    },
  },
  labDeptId: {
    required: {
      value: true,
      message: "Please Select Lab Type",
    },
  },
  labTechnicianId: {
    required: {
      value: true,
      message: "Please Select Lab Technician",
    },
  },
  location: {
    required: {
      value: true,
      message: "Please Enter Location",
    },
  },
};

export const labPackages = {
  labTestGroup: {
    required: {
      value: true,
      message: "Please Select Package Group",
    },
    minlength: {
      value: 1,
      message: "Please select atleast one",
    },
  },

  labPackageName: {
    required: {
      value: true,
      message: "Please Enter Lab Package Name",
    },
  },
  discount: {
    required: {
      value: true,
      message: "Please Enter Discount",
    },
  },
};
export const labTestGroup = {
  labTestGroupName: {
    required: {
      value: true,
      message: "Please Enter Lab Test Group Name",
    },
  },
  labDeptId: {
    required: {
      value: true,
      message: "Please Enter Lab Department",
    },
  },
  labTests: {
    required: {
      value: true,
      message: "Please Select Tests",
    },
    minlength: {
      value: 1,
      message: "Please select atleast one",
    },
  },
};

export const drugManufacture = {
  manufacturerName: {
    required: {
      value: true,
      message: "Please Enter Manufacturer Name",
    },
  },
  mobile: {
    required: {
      value: true,
      message: "Please Enter Mobile Number",
    },
  },
  address: {
    required: {
      value: true,
      message: "Please Enter Address",
    },
  },
  details: {
    required: {
      value: true,
      message: "Please Enter Details",
    },
  },
};

export const medicineCategory = {
  categoryName: {
    required: {
      value: true,
      message: "Please Enter Medicine Category",
    },
  },
};

export const medicines = {
  manufacturerId: {
    required: {
      value: true,
      message: "Please Enter Manufacturer Name",
    },
  },
  medicineCategoryId: {
    required: {
      value: true,
      message: "Please Select Medicine Category",
    },
  },
  genericName: {
    required: {
      value: true,
      message: "Please Enter Generic Name",
    },
  },
  medicineName: {
    required: {
      value: true,
      message: "Please Enter Medicine Name",
    },
  },
  reOrderLevel: {
    required: {
      value: true,
      message: "Please Enter Re-Order Leval",
    },
  },
};
export const purchase = {
  purchaseCode: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
  vendorId: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
  receivedDate: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
  orderedDate: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
};
export const indent = {
  departmentId: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
  indentCode: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
  indentReceivedDate: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
  indentPlacedDate: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
};
export const tests = {
  labTestName: {
    required: {
      value: true,
      message: "Please Enter Lab Test Name",
    },
  },
  labId: {
    required: {
      value: true,
      message: "Please Select Lab",
    },
  },
  cost: {
    required: {
      value: true,
      message: "Please Enter Cost",
    },
  },
};
export const medicinePurchase = {
  purchaseNumber: {
    required: {
      value: true,
      message: "Please Enter Purchase Number",
    },
  },
  purchaseDate: {
    required: {
      value: true,
      message: "Please Select Date",
    },
  },
  vendorId: {
    required: {
      value: true,
      message: "Please Select Vendor",
    },
  },
  batchNo: {
    required: {
      value: true,
      message: "Please Enter Batch Number",
    },
  },
  // medicineId:{
  //     required:{
  //         value:true,
  //         message:"Please Select Medicine"
  //     }
  // },
  // quantity:{
  //     required:{
  //         value:true,
  //         message:"Please Enter Quantity"
  //     }
  // },
  // price:{
  //     required:{
  //         value:true,
  //         message:"Please Enter  Price"
  //     }
  // },
  // salePrice:{
  //     required:{
  //         value:true,
  //         message:"Please Enter Sale Price"
  //     }
  // },
  // mfgDate:{
  //     required:{
  //         value:true,
  //         message:"Please Select Date"
  //     }
  // },
  // expiryDate:{
  //     required:{
  //         value:true,
  //         message:"Please Select Date"
  //     }
  // },
};
export const labappointmentvalidation = {
  patientId: {
    required: {
      value: true,
      message: "Please Select Patient",
    },
  },
  // labPackageId: {
  //   required: {
  //     value: true,
  //     message: "Please Select Package",
  //   },
  // },
  // labTests: {
  //   required: {
  //     value: true,
  //     message: "Please Select Test",
  //   },
  //   minlength: {
  //     value: 1,
  //     message: "Please select atleast one",
  //   },
  // },
  refferalPhysicianId: {
    required: {
      value: true,
      message: "Please Select Refferal Clinician ",
    },
  },
  collectionAgent: {
    required: {
      value: true,
      message: "Please Select Collection Agent",
    },
  },
  requestedDateTime: {
    required: {
      value: true,
      message: "Please Select Date",
    },
  },
};

export const department = {
  departmentName: {
    required: {
      value: true,
      message: "Please Enter Department Name",
    },
  },
  departmentCode: {
    required: {
      value: true,
      message: "Please Enter Department Code",
    },
  },
};
export const unit = {
  unitName: {
    required: {
      value: true,
      message: "Please Enter Units",
    },
  },
};
export const designation = {
  designationName: {
    required: {
      value: true,
      message: "Please Enter Designation",
    },
  },
};

export const items = {
  itemName: {
    required: {
      value: true,
      message: "Please Enter Item Name",
    },
  },
  itemCode: {
    required: {
      value: true,
      message: "Please Enter Item Name",
    },
  },
  itemCategoryId: {
    required: {
      value: true,
      message: "Please Select Item Category",
    },
  },
  itemReOrderLevel: {
    required: {
      value: true,
      message: "Please Enter Re-Order-Level",
    },
  },
  inUnitId: {
    required: {
      value: true,
      message: "Please Select Unit",
    },
  },
  outUnitId: {
    required: {
      value: true,
      message: "Please Select Unit",
    },
  },
  basicPrice: {
    required: {
      value: true,
      message: "Please Enter Basic Price",
    },
  },
  mrp: {
    required: {
      value: true,
      message: "Please Enter MRP",
    },
  },
  itemDescription: {
    required: {
      value: true,
      message: "Please Enter Description",
    },
  },
};
export const itemCategory = {
  itemCategoryName: {
    required: {
      value: true,
      message: "Please Enter Item Category",
    },
  },
};

export const vendor = {
  vendorName: {
    required: {
      value: true,
      message: "Please Enter Vendor Name",
    },
  },
  vendorPhone: {
    required: {
      value: true,
      message: "Please Enter Vendor Phone",
    },
  },
  contactPersonName: {
    required: {
      value: true,
      message: "Please Enter Contact Person Name",
    },
  },
  vendorEmail: {
    required: {
      value: true,
      message: "Please Enter Vendor Email",
    },
  },
  vendorCode: {
    required: {
      value: true,
      message: "Please Enter Vendor Code",
    },
  },
  vendorAddress: {
    required: {
      value: true,
      message: "Please Enter Vendor Address",
    },
  },
  vendorAccountDetails: {
    required: {
      value: true,
      message: "Please Enter Vendor Account Details",
    },
  },
  contractId: {
    required: {
      value: true,
      message: "Please Enter Vendor ContractId",
    },
  },
};

export const biowaste = {
  cbwtfId: {
    required: {
      value: true,
      message: " Please Select CbwtfId  ",
    },
  },
  colorCode: {
    required: {
      value: true,
      message: "Please Enter Color Code",
    },
  },
  weight: {
    required: {
      value: true,
      message: "Please Enter Weight",
    },
  },
  sourceDatetime: {
    required: {
      value: true,
      message: "Please Select Source Date",
    },
  },
  latitude: {
    required: {
      value: true,
      message: "Please Enter Latitude",
    },
  },
  longitude: {
    required: {
      value: true,
      message: "Please Enter longitude",
    },
  },
};

export const cbwtf = {
  cbwtfName: {
    required: {
      value: true,
      message: " Please Select Cbwtf Name ",
    },
  },
  address: {
    required: {
      value: true,
      message: "Please Enter Address",
    },
  },
  state: {
    required: {
      value: true,
      message: "Please Enter State",
    },
  },
  pincode: {
    required: {
      value: true,
      message: "Please Enter Pincode",
    },
  },
  latitude: {
    required: {
      value: true,
      message: "Please Enter Latitude",
    },
  },
  longitude: {
    required: {
      value: true,
      message: "Please Enter longitude",
    },
  },
};

export const paymentValidationSchema = (paymentType) => {
  switch (paymentType) {
    case "UPI":
      return {
        upi: {
          required: {
            value: true,
            message: "Plese Enter UPI",
          },
        },
        bank: {
          required: {
            value: true,
            message: "Plese enter bank",
          },
        },
      };
    case "Credit/Debit card":
      return {
        cardNumber: {
          required: {
            value: true,
            message: "Plese Enter Card number",
          },
        },
        nameOnCard: {
          required: {
            value: true,
            message: "Plese Enter Name",
          },
        },
        mm: {
          required: {
            value: true,
            message: "Plese Enter MM",
          },
        },
        yy: {
          required: {
            value: true,
            message: "Plese Enter YY",
          },
        },
        cvv: {
          required: {
            value: true,
            message: "Plese Enter CVV",
          },
        },
      };
    case "OTP":
      return {
        otp: {
          required: {
            value: true,
            message: "Plese Enter OTP",
          },
        },
      };
  }
};

export const discharge = {
  followUpDate: {
    required: {
      value: true,
      message: "Plese Select Followup Date",
    },
  },
  feedbackNotes: {
    required: {
      value: true,
      message: "Plese Select Enter Feedback",
    },
  },
  dischargeSummary: {
    required: {
      value: true,
      message: "Plese Select Enter Discharge Summary",
    },
  },
};
export const collection = {
  collectionAgent: {
    required: {
      value: true,
      message: "Please Select Collection Agent",
    },
  },
  sampleCollectedDate: {
    required: {
      value: true,
      message: "Please Select Collected Date",
    },
  },
};

export const quickAppointment = {
  teleConsultation: {
    required: { value: true, message: "Please Select Video Consultataion" },
  },
  patientId: {
    required: { value: true, message: "Please Select Patient" },
  },
  appointmentName: {
    required: { value: true, message: "Please Enter Appointment Name" },
  },
  appointmentName: {
    required: { value: true, message: "Please Enter Reason for Consultation" },
  },
  specialityId: {
    required: {
      value: true,
      message: "Please Select Speciality",
    },
  },
  physicianId: {
    required: {
      value: true,
      message: "Please Select Speciality",
    },
  },
};
export const setAvailability = {
  startDate: {
    required: {
      value: true,
      message: "Please Select Start Date",
    },
  },
  endDate: {
    required: {
      value: true,
      message: "Please Select End Date",
    },
  },
  startTime: {
    required: {
      value: true,
      message: "Please Select Start Time",
    },
  },
  endTime: {
    required: {
      value: true,
      message: "Please Select End Time",
    },
  },
};
export const pincode = {
  zoneId: {
    required: {
      value: true,
      message: "Please Select Zone",
    },
  },
  pincode: {
    required: {
      value: true,
      message: "Please Enter PinCode",
    },
  },
  areaName: {
    required: {
      value: true,
      message: "Please Enter Area Name",
    },
  },


};
export const zones = {
  zoneName: {
    required: {
      value: true,
      message: "Please Enter Zone",
    },
  },
  color: {
    required: {
      value: true,
      message: "Please Enter Color",
    },
  },



};

export const credValidationSchema = (status) => {
  if (status == "4") {
    return {
      credStatus: {
        required: {
          value: true,
          message: "Please select option",
        },
      },
      tempApproveDate: {
        required: {
          value: true,
          message: "Please select Temporary approve date",
        },
      },
    };
  } else {
    return {
      credStatus: {
        required: {
          value: true,
          message: "Please select option",
        },
      },
    };
  }
};


export const cashReconciliationTrans = {
  transDetails: {
    required: {
      value: true,
      message: "Please Enter transDetails",
    },
  },
  transAmount: {
    required: {
      value: true,
      message: "Please Enter transAmount",
    },
  },
  transDate: {
    required: {
      value: true,
      message: "Please Enter transAmount",
    },
  },
}