import React, { useState } from "react";
import JitsiMeet from "./JitsiMeet";
import "./jitsi.css";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
const CallStatus = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN PROGRESS",
  ENDED: "ENDED",
};
const JitsiVideo = () => {
  const navigate = useNavigate();
  const { displayName, roomName } = useParams();
  const [callStatus, setCallStatus] = useState(CallStatus.PENDING);
  const callInProgress = callStatus === CallStatus.IN_PROGRESS;
  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   setCallStatus(CallStatus.IN_PROGRESS);
  // };
  const onCallEnd = () => {
    setCallStatus(CallStatus.ENDED);
    navigate("/v1/practice/appointment/dashboard");
  };

  useEffect(() => {
    setCallStatus(CallStatus.IN_PROGRESS);
  }, []);

  useEffect(() => {
    console.log(callStatus, "callStatus");
  }, [callStatus]);

//   frame.addEventListener("load", ev => {
//     const new_style_element = document.createElement("style");
//     new_style_element.textContent = ".my-class { display: none; }"
//     ev.target.contentDocument.head.appendChild(new_style_element);
// });
  return (
    <div className="App">
      {callStatus != "PENDING" && <botton className="btn addbtn" onClick={()=>onCallEnd()}>Goto Dashboard</botton>}
      {!callInProgress && (
        <>
          <header className="header">
            <img src="https://previews.123rf.com/images/microone/microone1907/microone190700323/128173861-video-conference-people-group-on-computer-screen-taking-with-colleague-video-conferencing-and-online.jpg" alt="logo" />
            <h2>Join to the meet!</h2>
          </header>
          <article className="body">
            {/* <form onSubmit={onSubmit}> */}
            <form>
              <section className="formField">
                <input
                  type="text"
                  placeholder="Your name"
                  value={displayName}
                  // onChange={(e) => setDisplayName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Room's name"
                  value={roomName}
                  // onChange={(e) => setRoomName(e.target.value)}
                />
              </section>
              <section className="formActions">
                <button type="submit">Start conference</button>
              </section>
            </form>
          </article>
        </>
      )}
      {callInProgress && <JitsiMeet roomName={roomName} displayName={displayName} onEnd={onCallEnd} />}
    </div>
  );
};

export default JitsiVideo;
