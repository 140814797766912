import React, { useEffect, useState } from "react";
import { getList } from "../../ConfigFiles/SharedFunctions";
import { urls } from "../../ConfigFiles/Consts";
import Offers1 from "../../Images/commingsoon.jpg"
const Offers = () => {
  const [offers, setOffers] = useState([])
  const getAllOffers = async () => {
    setOffers(await getList(urls?.offer?.getAll, { id: 0 }))
  }

  useEffect(() => { getAllOffers() }, [])
  return (

    <div className="">
      {/* {offers.map((e,i)=><div className="col-md-4" key={i}>
<div class="card mb-3"  >
  <div class="row">
    <div class="col-md-4">
      <img src="http://unsplash.it/1000" class="card-img h-100" alt="..."/>
    </div>
    <div class="col-md-8">
      <div class="card-body">
        <h5 class="card-title"> {e?.offerName}</h5>
        <p class="card-text">{e?.offerDescrption} </p>
        <p class="card-text">{e?.hospitalName}</p>
        <p class="card-text"><small class="text-muted">From: {e?.offerFrom} To {e?.offerTo}</small></p>
      </div>
    </div>
  </div>
  </div>
  </div>)} */}
      <img src={Offers1} style={{ objectFit: "fit", height: "100vh", width: "100%" }} />
    </div>
  );
};

export default Offers;
