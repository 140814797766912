import { useEffect, useState } from "react"
import { checkLoginStatus, getUserfromSS, jwtDecodedData, logOutFunction } from "../../ConfigFiles/SmallFunctions"
import Login from "../Auth/Login"

const AROHSPrivateRoute = ({ children }) => {

    // const checkLoginStatus = () => {
    //     let authenticated = false
    //     let token = sessionStorage.getItem('token')
    //     if (Object.keys(jwtDecodedData())?.length > 0 && token != '') {
    //         authenticated = jwtDecodedData()?.id == getUserfromSS('userId') == true ? true : false
    //     }
    //     return authenticated
    // }

    return checkLoginStatus() ? children : logOutFunction()
    // return checkLoginStatus() ? children : <Login />
}

export default AROHSPrivateRoute