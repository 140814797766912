import React from 'react'
import { css, } from '../../ConfigFiles/Consts'
import { TbEmergencyBed } from "react-icons/tb"
import { RiChatFollowUpLine } from "react-icons/ri"
import { MdOutlineDirectionsWalk } from "react-icons/md"
import {GiTestTubes} from "react-icons/gi"
import { useNavigate } from 'react-router-dom'

const AppointmentTypeModelLanding = ({ openQuickAppointment, recurringAppointment, openFollowUpAppointment }) => {
    const navigate = useNavigate()
    return (
        <div className="m-1 text-start  p-2 d-flex flex-column">
            <div className='col-md-12 m-1  gap-3 py-2 border rounded fs-5 fw-semibold  d-flex  align-items-center' style={{  backgroundColor: css.Booked,cursor: "pointer" }} onClick={openQuickAppointment}>
            <div>    <MdOutlineDirectionsWalk /></div>
                <div>  Single Appointment </div>
            </div>
            <div className='col-md-12 m-1  gap-3 py-2 border rounded fs-5 fw-semibold  d-flex  align-items-center' style={{ backgroundColor: css.red, cursor: "pointer" }} onClick={recurringAppointment} >
                <div>   <RiChatFollowUpLine  className='ms-1'/></div>
                <div>  Recurring Appointment</div>
            </div>
            <div className='col-md-12 m-1  gap-3 py-2 border rounded fs-5 fw-semibold  d-flex  align-items-center' style={{ backgroundColor: css.Preadmission, cursor: "pointer" }} onClick={openFollowUpAppointment}  >
                <div>    <GiTestTubes className='ms-1' /></div>
                <div>    Lab Appointment </div>
            </div>
        </div>
    )
}

export default AppointmentTypeModelLanding