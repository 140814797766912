import React, { useState, useRef, useEffect } from "react";
import { css, urls } from "../../ConfigFiles/Consts";
import { returningValue } from "../../ConfigFiles/SmallFunctions"
import { SCGetResponce, SCResponce } from "../../ConfigFiles/SharedFunctions"


const ChatBot = () => {
  const messageListRef = useRef();
  const [names, setNames] = useState([])
  const [question, setQuestion] = useState({})
  const [answers, setAnswers] = useState([])
  const [selectedName, setSelectedName] = useState(null)
  const [result, setResult] = useState({})


  const getNames = async () => {
    let res = await SCGetResponce(urls.SymptomCheck.getNames, {})
    setNames(Array.isArray(res?.data?.data) ? res?.data?.data : [])
  }

  const getQuestions = async () => {
    let res = await SCResponce(urls.SymptomCheck.getQuestions, { data: selectedName?.indicators })
    console.log(res?.data?.data, "res")
    setQuestion(Array.isArray(res?.data?.data) ? res?.data?.data?.[0] : {})
  }
  useEffect(() => {
    console.log(question, "qu")
  }, [question])

  const giveAnswer = async (status) => {
    let res = await SCResponce(urls.SymptomCheck.getAnswers, { data: question?.indicators, Status: status })
    setAnswers((p) => [...p, { ...question, status: status }])
    if (returningValue(res?.data?.jump_questions, "") != '') {
      setQuestion({ id: 0, indicators: res?.data?.jump_questions })
    } else {
      setQuestion({})
      setResult(res?.data)
    }
  }

  useEffect(() => { getNames() }, [])
  useEffect(() => { returningValue(selectedName?.indicators, '') != '' && getQuestions() }, [selectedName?.indicators])




  const scrollToBottom = () => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  };

  // useEffect(() => {
  //   scrollToBottom()
  // }, [ question, answers, result])


  return (
    <div className="col-md-12 maincontnt text-black ">

      <div className="d-flex  justify-content-center gap-5 p-5 ">
        <div className=" ">
          <div className="chat-container  ">
            <div ref={messageListRef} className="message-list ">
              <div className='d-flex flex-column gap-2  p-2'>
                {selectedName == null ? <>
                  {names?.map((e, i) => (
                    <div
                      style={{ borderRadius: "20px", backgroundColor: selectedName == e ? css.themeColor : "", color: selectedName == e ? "white" : "black" }}
                      key={i}
                      onClick={() => setSelectedName(selectedName == null ? e : selectedName)}
                      className={`ps-4 p-2 border border-dark  pointer`}
                    >{e?.indicators}</div>
                  ))}</> : <>
                  <div
                    style={{ borderRadius: "20px", backgroundColor: css.themeColor, color: "white" }}
                    className={`ps-4 p-2 border border-dark  pointer`}
                  >{selectedName?.indicators}</div>
                </>}
                {answers?.length > 0 && answers?.map((e, i) => (
                  <div className='d-flex flex-column gap-2 ' key={i} style={{ color: "black" }}>
                    <div className={`ps-4 p-2 border w-100 rounded pointer d-flex flex-column gap-2`}>
                      <div className="">{e?.indicators}</div>
                      <div className="text-end "><span className=" p-2 rounded"> Answer: {e?.status?.toUpperCase()}</span></div>
                    </div>
                  </div>
                ))}
                {Object.keys(question)?.length > 0 &&
                  <div className=' ' style={{ color: "black" }} >
                    <div className='d-flex flex-column gap-2 '>
                      <div className={`ps-4 p-2 border rounded pointer d-flex flex-column gap-2`}>
                        <div>{question?.indicators}</div>
                        <div className='d-flex justify-content-center gap-4'>
                          <button className='addbtn' onClick={() => giveAnswer('yes')}>Yes</button>
                          <button className='cancelbtn' onClick={() => giveAnswer('no')}>No</button>
                        </div>
                      </div>
                    </div>
                  </div>}
                {Object.entries(result).map(([key, value]) => (
                  <div style={{ color: "black" }}>
                    <h5 className="">{key} :</h5>
                    <div>{value}</div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div ref={messageListRef} className="message-list ">
              <MessageList messages={messages} />
            </div>
            <MessageInput
              input={input}
              setInput={setInput}
              sendMessage={sendMessage}
            /> */}
          </div>
        </div>
      </div>

    </div>
  );
};
export default ChatBot;
