import React from "react";
import QRCode from "qrcode.react";

const QRforAppointment = ({ closeAndUpdate, close, updatingData }) => {
    const generateQRCodeData = () => {
        // return JSON.stringify(updatingData);
        return `https://medverz.com/bookappointment`;
    };

    return (
        <div className="d-flex justify-content-center py-4">
            <QRCode size={150} value={generateQRCodeData()} />
        </div>
    );
};

export default QRforAppointment;
