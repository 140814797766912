export const genderList = ["Male", "Female", "Other"];
export const marritialList = ["Single", "Married", "Widow"];
export const marritialListDubai = ["Separated", "Divorced", "Married", "Single", "Unknown", "Widowed", "Childe"];
export const uniqueFamilyIdentifier = ["Family", "Unrelated friend", "Father", "Mother", "Next of kin", "Sibling", "Spouse"];
export const employeementStatus = ["Public Sector Employee", " Government Employee (Military)", "Private Sector Employee", "Freelancer", "Volunteer - Co-op", "Student", "Housewife", "Retired", "Unemployed (Can work)", "Unemployed (Cannot work)", "Other", "Unknown", "Refused to answer"];
export const insuranceElgibility = ["Public sector (non-military nor education)", "Military", "National guard", "Ministry of interior", "Education", "Private", "Royal commission", "KFSHRC", "Aramco"];
export const relationList = ["Mother", "Father", "Brother", "Others"];
export const profession = ["Administration", "Agriculture", "Business", "Education", "Housewife", "Marine", "Medical field", "Military", "Skilled worker", "Student", "Oil industries", "Unemployed", "Others", "Unknown"];
export const educationStatus = ["Illiterate", "Read and Write", "Primary", "Intermediate", "Secondary", "Dip.LTUniv", "University", "Masters/High Dip.", "Ph. D.", "Unknown"];
export const generalHealthStatus = ["Very good", "Good", "Fair", "Bad", "Very bad?"];
export const levelofactivity = ["150 minutes or more", "From 75 minutes to 149 minutes", "From 0 minutes to 75 minutes", "No activity", "Unknown"];
export const insrelationList = ["Self", "Spouse", "Child", "Other"];
export const smoking = ["No", "Yes, started within the last year", "Yes, started before last year", "Ex-Smoker", "Refused to answer"];
export const ChronicconditionDiabetes = ["No", "Type 1", "Type 2", "Impaired Glucose Homeostasis", "Gestational Diabetes", "Prediabetic", "Other", "Unknown"];
export const hematologicConditions = ["No", "Sickle-cell disorders", "Thalassaemia and other genetic haemolytic", "Acquired haemolytic anaemia"];
export const ChronicConditionCancer = ["Lymphoma", "Leukemia", "Malignant CNS and Peripheral Nerves", "Non-Malignant CNS", "Breast", "Colon", "Thyroid", "Kidney", "Lung and respiratory system", "Urinary sites", "Gastrointestinal sites (Other than Liver)", "Liver", "Prostate", "Ovary and female reproductive system", "Others", "Unknown"];
export const Dementia = ["No", "Dementia", "Alzheimer's Disease", "Parkinsonism"];
export const MentalCondition=["Anxiety Disorders",
  "Depression disorder",
  "Bipolar disorder",
  "Eating disorder",
"Personality disorder",
  "Post-Traumatic stress disorder",
"Psychotic Disorders",
  "Others",
  "Unknown"
  ]
export const CardioVescular=["Arrhythmia",
  "Cardiomyopathies",
  "Myocardial Infarction (Ischemic Heart Disease)",
  "Rheumatic Heart Disease",
  "Pericarditis",
  "Others",
  "Unknown"
  ]
  export const CommunicableDiseases=["Hepatitis A",
  "Hepatitis B",
    "Hepatitis C",
    "Dengue Fever",
    "Brucellosis",
    "Leishmaniasis",
    "Tuberculosis",
    "Lower Respiratory Tract Infections",
    "Upper Respiratory Tract Infections",
  "Malaria",
"Leprosy",
"Meningitis",
"Unknown"

    ]
    export const severeChronicConditionsRequireTransplan=["Heart",
      "Lung",
      "Kidney",
      "Liver",
    "Dialysis" ,
      "Others",
      "Unknown"
      ]
  export const Asthma=["Asthma",
    "Chronic Bronchitis",
    "Emphysema",
    "Unknown"
    ]
    export const disability=["Sight",
      "Self-care",
      "Hearing",
      "Mobility",
      "Memory and concentration",
      "Communication",
      "Others",
      "Unknown"
      ]
export const seStatesList = ["Alaska", "Newyork", "Huwai"];
export const seCountryList = ["India", "USA", "Uk"];
export const yesOrNoList = ["Yes", "No"];
export const insuranceTypeList = ["Primary", "Secondary", "Tertiary"];
export const addressTypeList = ["Present", "Permanent"];
export const wardTypeList = ["General/Male", "General/Female", "Special/Male", "Special/Female"];
export const testResults = ["Pending", "Performed", "Rejected"];
export const testStatus = ["Positive", "Negative","N/A"];

export const paymentStatus = ["Pending", "Paid"];

export const paymentMethods = ["Debit Card", "Credit Card", "Cash", "UPI"];

export const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export const RoomType = ["Consultation Room", "Sevice Room", "OT Room", "Wards"];
export const status = ["Active", "InActive"];
export const appointmentStatus = ["Requested", "Booked", "Consulted", "Sevice", "Completed"];
export const timesPer = ["Day", "Week", "Month", "As needed"];
export const timesOfDay = ["AM", "PM", "Both", "With Meals"];
export const dosageList = ["mg", "drops", "fl.oz", "g", "ic", "mcg", "ml", "oz", "fbsp", "tsp", "units/ml"];
export const medicationRoute = ["G-tube", "Infusion", "Inhaled ", "Injections", "Intramuscular Injection", "Instill", "Nasal", "Oral", "Rectal", "Skin", "Sub-Lingual", "Subcutaneous Injection", "Subcutaneous Solution", "Subdermal Implant", "Topical", "Unknown or N/A", "Unsure", "Vaginal"];

export const attachmentCertificates = ["Driving Licence", "Passport", "CME Certificate", "FellowShip Certificate", "Recidency Certificate", "Graduate Certificate", "Internship Certificate", "Undergraduate Certificate", "ACLS Certificate", "CDS Certificate", "Board Certificate", "DEA Certificate", "Medical Licence Certificate", "Hospital Privilege Letters for all current facilities", "Last Flu Shot(Last 12 Months)", "PPD Results(Last 12 Months)"];

export const credStatusList = [
  { name: "Approve", value: "3" },
  { name: "Temporarily approve", value: "4" },
  { name: "Reject", value: "5" },
];

export const roles = [
  { roleId: 1, rolename: "Admin" },
  { roleId: 2, rolename: "Organization Head" },
  { roleId: 3, rolename: "Hospital Admin" },
  { roleId: 4, rolename: "Physician" },
  { roleId: 5, rolename: "Patient" },
  { roleId: 6, rolename: "Lab Collection Agent" },
  { roleId: 7, rolename: "Lab Technician" },
  { roleId: 8, rolename: "Non Login Users" },
  { roleId: 9, rolename: "Nurse" },
  { roleId: 10, rolename: "HOD" },
  { roleId: 11, rolename: "Receptionist" },
  { roleId: 12, rolename: "IT Admin" }, 
];
