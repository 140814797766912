import React, { useState } from "react";
import { FaRegQuestionCircle } from "react-icons/fa";
import { basefileURL, css, urls } from "../../ConfigFiles/Consts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangImg from "../../Images/landImg.png";
import Modal from "react-bootstrap/Modal";
import AppointmentTypeModelLanding from "./AppointmentTypeModelLanding";
import { useEffect } from "react";
import { getList } from "../../ConfigFiles/SharedFunctions";
import { checkLoginStatus, navigationFn } from "../../ConfigFiles/SmallFunctions";
import { CgProfile } from "react-icons/cg";
import LandLog from "../../Images/landLogo.png";
import { HiOutlineArrowRight } from "react-icons/hi";
import { IoMenu } from "react-icons/io5";
import QRforAppointment from "./QRforAppointment";
const LandingPage = () => {
  const navigate = useNavigate();
  const [updatingData, setUpdatingData] = useState({});
  const [model, setModel] = useState({});
  const [update, setUpdate] = useState(Date.now());
  const [offers, setOffers] = useState([]);
  const [broken, setBroken] = useState(window.matchMedia("(max-width: 992px)").matches);
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    if (checkLoginStatus()) {
      let route = navigationFn(JSON.parse(sessionStorage.getItem("user")));
      navigate(route);
    }
  }, []);

  const getAllOffers = async () => {
    setOffers(await getList(urls?.offer?.getAll, { id: 0 }));
  };

  const changeModel = (name, q) => {
    setModel({
      // ...model,
      [name]: model?.[name] == true ? false : true,
    });
    setUpdatingData(q || {});
  };

  const changeandUpdateModel = (name, q) => {
    setModel({
      // ...model,
      [name]: model?.[name] == true ? false : true,
    });
    setUpdatingData(q || {});
    setUpdate(Date.now());
  };
  const handleShowNavbar = (e) => {
    e.stopPropagation();
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    const handleResize = () => {
      setBroken(window.matchMedia("(max-width: 992px)").matches);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleParentClick = () => setShowNavbar(false)

  useEffect(() => {
    getAllOffers();
  }, []);
  return (
    <div className="text-  " style={{ height: "98vh" }} onClick={handleParentClick}>
      <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={model?.appointmenttypemodellanding} onHide={() => changeModel("appointmenttypemodellanding")}>
        <AppointmentTypeModelLanding
          openQuickAppointment={() => {
            navigate("/bookappointment");
          }}
          recurringAppointment={() => {
            navigate("/recurringappointment");
          }}
          openFollowUpAppointment={() => {
            navigate("/booklabappointment");
          }}
        />
      </Modal>
      <div className=" landingMain   gap-2  col-md-12 " style={{ height: "10vh", marginTop: "2vh" }}>
        <div className="col-md-6 ">
          <img src={LandLog} height={100} className="" />
        </div>
        {!broken && <div className="">
          <div className="d-flex">
            <button className="landButton  gap-2  m-3" onClick={() => navigate("/login")}>
              <CgProfile size={22} /> Login
            </button>
            <button className=" landButton   m-3" onClick={() => navigate("/enquiry")}>
              <FaRegQuestionCircle size={22} /> Enquiry
            </button>

          </div>
        </div>}
        {broken && <div className='  '>

          <div className="menu-icon" onClick={(e) => handleShowNavbar(e)}>
            <IoMenu size={25} />
          </div>
          <div className={`nav-elements  ${showNavbar && "active"}`}>
            <ul>


              <li onClick={() => navigate("/login")}>Login</li>
              <li onClick={() => navigate("/enquiry")}> Enquiry</li>


            </ul>
          </div>
        </div>
        }
      </div>
      <div className="d-flex justify-content-between   col-md-11" style={{ height: "80vh" }}>
        <div className="col-11 col-sm-12 col-lg-6 col-md-12 d-flex  gap-4 flex-column align-items-center justify-content-center ps-5 ">
          <div className="paddingL padding4 ps-4 d-flex flex-column mt-4  ">
            <div className="d-flex  justify-content-between align-items-center col-md-11">
              <div className=" paddingL  landingFont col-md-8 col-lg-6" >
                Your Health, Wellness & Wellbeing  Start here
              </div>

              <div className=" text-center me-4">
                <QRforAppointment updatingData={""} />
              </div>
            </div>
            <div className=" fs-5 paddingL text-secondary my-2">Explore our health care server</div>
            <div className="paddingL d-flex flex-wrap col-12 gap-2 ">
              <div className="landButton col-md-5  col-sm-8 col-11  text-cente d-flex justify-content-star align-items-center" onClick={() => changeModel("appointmenttypemodellanding")}>
                <div className="col-md-10">Appointment</div>
                <div className="text-end">
                  {" "}
                  <HiOutlineArrowRight className="" size={20} />
                </div>
              </div>
              <div className="landButton col-md-5 col-sm-8 col-11  d-flex justify-content-start align-items-center py-2 " onClick={() => navigate("/login")}>
                <div className="col-md-10">Health Record</div> <HiOutlineArrowRight size={20} />
              </div>
              <div className="landButton col-md-5  col-sm-8 col-11   d-flex justify-content-start align-items-center py-2 " onClick={() => navigate("/careplans")}>
                <div className="col-md-10">Packages</div> <HiOutlineArrowRight size={20} />
              </div>

              <div className="landButton col-md-5  col-sm-8 col-11   d-flex justify-content-start align-items-center py-2 " onClick={() => navigate("/offers")}>
                <div className="col-md-10">Offers </div>
                <HiOutlineArrowRight size={20} />
              </div>
            </div>
          </div>
          {/* <img src={bantia} height={180} className="card-container rounded" /> */}
        </div>
        <div className="  align-items-center landImgHide ">
          <img src={LangImg} height={450} style={{ objectFit: "contain" }} />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
