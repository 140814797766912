const initalState = {
  messages: [],
  connection: false,
};
const reducer = (state = initalState, action) => {
  switch (action.type) {
    case "ADDMESSAGES":
      return {
        ...state,
        messages: [...state.messages, ...action.payload],
      };
    case "CONNECTION":
      return {
        ...state,
        connection: action.payload,
      };
    default:
      return state;
  }
};

export { reducer };
