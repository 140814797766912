import React, { useEffect, useState } from "react";
import { getById, getList, save } from "../../ConfigFiles/SharedFunctions";
import { css, urls } from "../../ConfigFiles/Consts";
import { decryptData, returnData, returnDate, returnErrorColor, returningValue } from "../../ConfigFiles/SmallFunctions";
import { useFormValidation } from "../../Validations/useFormValidations";
import { useNavigate, useParams } from "react-router-dom";
import { genderList, yesOrNoList } from "../../ConfigFiles/ConstList";
import Raz from "../../ThirdPartyIntegrations/Razorpay/Razorpay";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TbCurrencyRiyal } from "react-icons/tb";
import Modal from "react-bootstrap/Modal";
import LabAppointmentDetails from "./LabAppointmentDetails";
import atonomy from "../../Images/bodystructure.png";
import { BiArrowBack } from "react-icons/bi";
import { BooklabAppointment } from "../../ConfigFiles/ValidationSchemas";
import Backimg from "../../Images/steth2.png"
const BookLabAppointment = () => {
  const navigate = useNavigate();
  const [hospitals, setHospitals] = useState([]);
  const [tests, setTests] = useState([]);
  const [model, setModel] = useState(false);
  const [labPackage, setLabPackage] = useState([]);
  const [selectedLabPackage, setSelectedlabPackage] = useState({});
  const [updatingData, setUpdatingData] = useState({});
  const [update, setUpdate] = useState(Date.now());
  const submit = async () => {
    let body = {
      ...data,
      patientAddress: { addressType: data?.addressType, nationalAddress: data?.nationalAddress, city: data?.city, pinCode: data?.pinCode, cluster: data?.cluster, neighbourhoodDistrict: data?.neighbourhoodDistrict },
      labTestIds: data?.selectedLabTests?.map((e) => e?.labTestId)?.toString(),
    };
    let res = await save(urls?.labAppointment?.bookLabAppointment, body);
    if (res?.data?.status == true) {
      navigate("/");
    }
  };
  const makePayment = () => {
    Object.assign(data, { paymentType: "Razorpay", paymentStatus: "Paid" });
    changeModel("payment");
    submit();
  };
  const { data, errors, addObject, setV, handleSubmit, handleChange, handleSelectMultiple, handleDateChange, formChange } = useFormValidation({
    initialValues: { selectedLabTests: [] },
    validationSchema: {},
    submit: () => changeModel("payment"),
    // submit: submit
  });
  const changeModel = (name, q) => {
    setModel({
      [name]: model?.[name] == true ? false : true,
    });
    setUpdatingData(q || {});
  };
  const changeandUpdateModel = (name, q) => {
    setModel({
      [name]: model?.[name] == true ? false : true,
    });
    setUpdatingData(q || {});
    setUpdate(Date.now());
  };
  const hospitalsGetAll = async () => {
    let res = await getList(urls?.hospital?.getAllHospitals);
    addObject({ hospitalId: 1 });
    setHospitals(res);
  };
  const getAllTests = async () => {
    let res = await getList(urls?.labTest?.getAll, { id: data?.hospitalId });
    setTests(res);
  };
  const getLabPackageList = async () => {
    let res = await getList(urls?.labPackage?.getAll, { id: data?.hospitalId });
    setLabPackage(res);
  };
  const setTestList = (e) => {
    if (data?.selectedLabTests?.filter((v) => v?.labTestId == e?.labTestId)?.length > 0) {
      addObject({ selectedLabTests: data?.selectedLabTests?.filter((v) => v?.labTestId != e?.labTestId) });
    } else {
      addObject({ selectedLabTests: [...data?.selectedLabTests, e] });
    }
  };
  const returnCost = () => {
    const sum = data?.selectedLabTests?.reduce((partialSum, a) => partialSum + a?.cost, 0) || 0;
    let labcost = labPackage?.filter((v) => v?.labPackageId == data?.labPackageId)?.[0]?.["cost"] || 0;
    let total = sum + labcost;
    addObject({ appointmentCost: total });
  };
  useEffect(() => {
    returnCost();
  }, [data?.selectedLabTests, data?.labPackageId]);
  useEffect(() => {
    hospitalsGetAll();
  }, []);
  useEffect(() => {
    getAllTests();
    getLabPackageList();
  }, [data?.hospitalId]);
  return (
    <>
      <div className="py-2" style={{ backgroundColor: "#fff" }}>
        {model?.payment == true && <Raz parentCallback={makePayment} amount={data?.appointmentCost * 100} />}
        <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={model?.moredetails} onHide={() => changeModel("moredetails")}>
          <LabAppointmentDetails labPackages={labPackage} labTests={tests} close={() => changeModel("moredetails")} closeAndUpdate={() => changeandUpdateModel("moredetails")} data={data} selectedLabPackage={selectedLabPackage} />
        </Modal>
        <div className="d-flex text-center justify-content-between p-1" style={{ height: "5vh" }}>
          {/* <div className=" fw-bold BookAppointment ">Book Lab Appointment</div> */}
          <BiArrowBack size={20} style={{ cursor: "pointer" }} className="" onClick={() => navigate("/")} />
          <div className=" d-flex gap-2  ">
            <div className="addbtn px-3 py-1" type="submit" onClick={handleSubmit}>
              Book Lab Test
            </div>
          </div>
        </div>
        <div className="row mx-1">
          <div className="col-md-6 col-lg-4 col-xl-4 row bg-white mt-2  border border-muted rounded  " style={{ maxHeight: "90vh", overflow: "auto" }}>
            <div className="col-md-12  p-2 ">
              <label className="text-size">Select Location</label>
              <select name="labPackageId" value={returnData(data, "hospitalId")} onChange={handleChange("hospitalId")} className={`form-select p-2 ${returnErrorColor(errors?.hospitalId)}`}>
                <option value={""}>Select</option>
                {hospitals?.map((e, i) => (
                  <option value={e?.hospitalId} key={i}>
                    {e?.hospitalName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">First Name </label>
              <input type="text" name="firstName" value={returnData(data, "firstName")} onChange={formChange("firstName", "Alphabet")} placeholder="First Name" className={`form-control p-2     ${returnErrorColor(errors?.firstName)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Last Name </label>
              <input type="text" name="lastName" value={returnData(data, "lastName")} onChange={formChange("lastName", "Alphabet")} placeholder="Last Name" className={`form-control p-2     ${returnErrorColor(errors?.lastName)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Gender </label>
              <select type="text" name="gender" value={returnData(data, "gender")} onChange={handleChange("gender")} placeholder="Gender" className={`form-control p-2 ${returnErrorColor(errors?.gender)}`}>
                <option value={""}>Select</option>
                {genderList?.map((e, i) => (
                  <option key={i}>{e}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Mobile </label>
              {/* <input maxLength={"10"} type="text" name="mobile" value={returnData(data, "mobile")}
                onChange={formChange("mobile", "NumberString")} placeholder="Mobile"
                className={`form-control p-2 ${returnErrorColor(errors?.mobile)}`} /> */}
              <input type="text" name="mobile"
                maxLength={15}
                value={returningValue(data?.["mobile"], "Phone")}
                onChange={formChange("mobile", "Phone")}

                placeholder="Mobile" className={`form-control p-2     ${returnErrorColor(errors?.mobile)}`} />
            </div>
            <div className="col-md-12  p-2">
              <label className="text-size">Email</label>
              <input type="text" name="email" value={returnData(data, "email")} onChange={handleChange("email")} placeholder="Email" className={`form-control p-2     ${returnErrorColor(errors?.email)}`} />
            </div>
            <div className="col-md-12 mb-2 p-2">
              <label className="text-size">
                Date Of Birth
              </label>
              <DatePicker
                className={`form-control border py-2 ${returnErrorColor(errors?.dob)}`}
                selected={data?.dob ? new Date(data.dob) : ""}
                minDate={new Date(1900, 1, 1)}
                maxDate={new Date()}
                onChange={(e) => {
                  handleDateChange(e, "dob");
                }}
                autoComplete="off"
                name="dob"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                popperClassName="react-datepicker-popper"
                showMonthDropdown
                showYearDropdown
                style={{ fontSize: "20px" }}
                dropdownMode="select"
              />
            </div>
            {/* <div className="col-md-6  p-2">
              <label className="text-size"> Address type </label>
              <input type="text" name="addressType" value={returnData(data, "addressType")} onChange={formChange("addressType", "Alphabet")} placeholder="  Address type" className={`form-control p-2     ${returnErrorColor(errors?.addressType)}`} />{" "}
            </div> */}
            <div className="col-md-6  p-2">
              <label className="text-size">National Address </label>
              <input type="text" name="nationalAddress" value={returnData(data, "nationalAddress")} onChange={formChange("nationalAddress", "Alphabet")} placeholder=" National Address" className={`form-control p-2     ${returnErrorColor(errors?.nationalAddress)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">City </label>
              <input type="text" name="city" value={returnData(data, "city")} onChange={formChange("city", "Alphabet")} placeholder="City" className={`form-control p-2     ${returnErrorColor(errors?.city)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Cluster</label>
              <input type="text" name="cluster" value={returnData(data, "cluster")} onChange={handleChange("cluster")} placeholder="Cluster" className={`form-control p-2     ${returnErrorColor(errors?.cluster)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size"> Neighbourhood/District</label>
              <input type="text" name="neighbourhoodDistrict" value={returnData(data, "neighbourhoodDistrict")} onChange={formChange("neighbourhoodDistrict", "Alphabet")} placeholder=" Neighbourhood/District" className={`form-control p-2     ${returnErrorColor(errors?.neighbourhoodDistrict)}`} />{" "}
            </div>

            {/* <div className="col-md-6  p-2">
              <label className="text-size">Zip code </label>
              <input type="text" name="pinCode" value={returnData(data, "pinCode")} maxLength={5} onChange={handleChange("pinCode")} placeholder="Zip code" className={`form-control p-2     ${returnErrorColor(errors?.pinCode)}`} />{" "}
            </div> */}
            {/* <div className="col-md-6  p-2">
              <label className="text-size">Longitude </label>
              <input type="text" name="longitude" value={returnData(data, "longitude")} maxLength={5} onChange={handleChange("longitude")} placeholder="Longitude" className={`form-control p-2     ${returnErrorColor(errors?.longitude)}`} />{" "}
            </div> <div className="col-md-6  p-2">
              <label className="text-size">Latitude </label>
              <input type="text" name="latitude" value={returnData(data, "latitude")} maxLength={5} onChange={handleChange("latitude")} placeholder="Latitude" className={`form-control p-2     ${returnErrorColor(errors?.latitude)}`} />{" "}
            </div> */}
            <div className="col-md-12 p-2">
              <label className="text-size">Requested Date</label>
              <DatePicker
                className={`border form-control py-2 ${returnErrorColor(errors?.requestedDateTime)}`}
                minDate={new Date()}
                maxDate={new Date()}
                autoComplete="off"
                selected={returnDate(data?.["requestedDateTime"])}
                onChange={(e) => handleDateChange(e, "requestedDateTime")}
                name="requestedDateTime"
                dateFormat="MM/dd/yyyy"
                placeholderText="MM/DD/YYYY"
                popperClassName="react-datepicker-popper"
                showMonthDropdown
                showYearDropdown
                style={{ fontSize: "20px" }}
                dropdownMode="select"
              />
            </div>
            <div className="col-md-12  p-2">
              <label className="text-size">Total Cost</label>
              <input
                type="text"
                name="cost"
                value={returnData(data, "appointmentCost")}
                disabled={true}
                // onChange={handleChange("cost")}
                placeholder="Total Cost"
                className={`form-control p-2 ${returnErrorColor(errors?.cost)}`}
              />
            </div>
          </div>
          {labPackage.length > 0 || tests.length > 0 ?
            <div className="d-flex col-xl-8">
              {labPackage.length > 0 && (
                <div className="col-md-6 col-lg-6 col-xl-6  bg-white mt-2  border-muted rounded px-2">
                  <div className="text-center fs-5 fw-bold p-2">Lab Package</div>
                  <div className="   d-flex  flex-wrap justify-content-around  border rounded p-2 " style={{ maxHeight: "80vh", overflow: "auto" }}>
                    {labPackage?.map((e, i) => {
                      return (
                        <div
                          class="card col-md-12  my-2  pointer "
                          onClick={() => {
                            addObject({ labPackageId: e?.labPackageId, selectedLabTests: [] });
                            setSelectedlabPackage(e);
                          }}
                          style={{ backgroundColor: data?.labPackageId == e?.labPackageId ? css.Booked : "#b9e1fa" }}
                        >
                          <div class="card-body">
                            <div class="   d-flex card-title   fw-bold  justify-content-between" style={{ height: "auto" }}>
                              <p>{e?.labPackageName}</p>
                              <small class="  card-subtitle text-muted" style={{ height: "auto" }}>
                                {" "}
                                Get Report within 24 hrs
                              </small>
                            </div>
                            <div class=" col-md-12 d-flex    fw-bold justify-content-between " style={{ height: "auto" }}>
                              <p>
                                {e?.cost} <TbCurrencyRiyal size={30} />
                              </p>
                              <br />
                              <p style={{ border: "1px dashed green  " }} className="text-success ">
                                {e?.discount ? e?.discount : "0"}% Off
                              </p>
                            </div>
                            <div class=" col-md-12 d-flex    fw-bold justify-content-between " style={{ height: "auto" }}>
                              <div class="card-subtitle mb-1   text-primary" style={{ height: "20px", cursor: "pointer" }} onClick={() => changeModel("moredetails", e)}>
                                + More
                              </div>
                              <br />
                              <p className="text-success ">Includes:{e?.labTestCount ? e?.labTestCount : "0"} Tests</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {tests.length > 0 && (
                <div className="col-md-6 col-lg-6 col-xl-6  bg-white mt-2  border-muted rounded px-2">
                  <div className="text-center fs-5 fw-bold p-2">Lab Test</div>
                  <div className="  d-flex  flex-wrap justify-content-around  p-2   border rounded pointer" style={{ maxHeight: "80vh", overflow: "auto" }}>
                    {tests
                      ?.filter((v) => (selectedLabPackage?.labTestIds?.split(",") || [])?.every((a) => a != v?.labTestId))
                      ?.map((e, i) => {
                        return (
                          <div class="card col-md-12  my-2" onClick={() => setTestList(e)} style={{ height: "auto", backgroundColor: data?.selectedLabTests?.filter((v) => v?.labTestId == e?.labTestId)?.length > 0 ? css.Booked : "#b9e1fa" }}>
                            <div class="card-body ">
                              <div class="   d-flex card-title   fw-bold  justify-content-between" style={{ height: "auto" }}>
                                <p>{e?.labTestName}</p>
                                <small class="  card-subtitle text-muted" style={{ height: "auto" }}>
                                  {" "}
                                  Get Report within 24 hrs
                                </small>
                              </div>
                              <div class=" col-md-12 d-flex    fw-bold justify-content-between " style={{ height: "auto" }}>
                                <p>
                                  {e?.cost} <TbCurrencyRiyal size={30} />
                                </p>
                                <br />
                                <p style={{ border: "1px dashed green  " }} className="text-success ">
                                  {e?.discount ? e?.discount : "0"}% Off
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
            : <div className=" d-flex  justify-content-center  col-8 steth2" style={{}}>

            </div>}
        </div>
      </div>
    </>
  );
};
export default BookLabAppointment;
