import React, { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { getfList, getList, save } from "../../ConfigFiles/SharedFunctions";
import { css, urls } from "../../ConfigFiles/Consts";
import {
  getUserfromSS,
  returnData,
  returnErrorColor,
 } from "../../ConfigFiles/SmallFunctions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
 import stethlogp from "../../Images/stethlogp.png";
import calendar from "../../Images/calendar-plus.png";
import { useFormValidation } from "../../Validations/useFormValidations";

const SearchForAppointment = () => {
  const [physicianAvailability, setPhysicianAvailability] = useState([]);
  const navigate = useNavigate();
  const [organization, setOrganization] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [physician, setPhysician] = useState([]);
  const searchby = ["Organization", "Hospital"];
 
  
  const organizationGetAll = async () => {
    let res = await getfList(urls?.organization?.GetAllWithoutToken);
    setOrganization(res);
  };
  const hospitalsGetAll = async () => {
    let res = await getList(urls?.hospital?.getAllHospitals);
    setHospitals(res);
  };

  const getPhysicians = async (a) => {
    var body = { organizationId: 0, hospitalId: 0, specialityId: 0 };
    body = { ...body, ...a };
    var res = await getList(urls?.user?.getPhysicianDetails, body);
    setPhysician(res);
    //  selectedPhysician.value = {};
  };

    const submit = async () => {
       let endA = new Date(data?.startActivity);
      endA.setMinutes(endA.getMinutes() + 30);
       data["startTime"] = data?.startActivity
      data["endTime"] = moment(endA).format().split("+")?.[0];
      console.log(data, "data");
      let body = {
     
         ...data,  
      };
      let res = await save(urls?.appointment?.bookAppointment, body);
      if (res?.data?.status == true) {
        navigate("/");
      }
    };
    const { data, errors, addObject, handleChange, handleSubmit } =
    useFormValidation({submit:submit});

  const GetAvailabilitywithtToken = async () => {
    setPhysicianAvailability(
      await getList(urls?.physician?.getAvailabilitywithoutToken, {
        id: data?.physicianId,
      })
    );
  };

  const returndates = () => {
    let days = physicianAvailability?.map((e, i) =>
      moment(e?.startActivity)?.format("MM-DD-YYYY")
    );
    var uni = days.filter(
      (value, index, array) => array.indexOf(value) === index
    );
    return uni || [];
  };

  const returnTimings = () => {
    let times =
      physicianAvailability?.filter(
        (v) => moment(v?.startActivity)?.format("MM-DD-YYYY") == data?.date
      ) || [];
    return times || [];
  };

 

  useEffect(() => {
    organizationGetAll();
    hospitalsGetAll();
  }, []);

  useEffect(() => {
    setPhysician([]);
  }, [data?.searchBy]);

  useEffect(() => {
    data?.physicianId > 0 && GetAvailabilitywithtToken();
  }, [data?.physicianId]);

  useEffect(() => {}, []);
  return (
    <div>
      <div
        className="d-flex text-center justify-content-between"
        style={{ height: "" }}
      >
        <div className="fs-5 fw-bold">Book Appointment</div>
        <button
          className="btn btn"
          style={{ backgroundColor: "purple", color: "white" }}
          onClick={handleSubmit}
        >
          Book Appointment
        </button>
      </div>
      <div className="d-flex ">
        <div className="col-md-6 p-2 ">
          <div className="text-center fs-4 fw-bold ">Find Your Doctor ?</div>
          <div className="d-flex">
            {searchby.map((e, i) => (
              <div className="p-1">
                <button
                  className=" btn btn bg-primary"
                  onClick={() => addObject({ searchBy: e })}
                >
                  {e}
                </button>
              </div>
            ))}
            {data?.["searchBy"] == "Organization" && (
              <div className="col-md-6 mb-2">
                <select
                  name="organizationId"
                  value={returnData(data, "organizationId")}
                  onChange={(e) => {
                    addObject({ organizationId: e.target.value });
                    getPhysicians({ organizationId: e.target.value });
                  }}
                  className={`form-select p-2 ${returnErrorColor(
                    errors?.organizationId
                  )}`}
                >
                  <option value={""}>Select</option>
                  {organization?.map((e, i) => (
                    <option value={e?.organizationId} key={i}>
                      {e?.organizationName}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {data?.["searchBy"] == "Hospital" && (
              <div className="col-md-6 mb-2">
                <select
                  name="hospitalId"
                  value={returnData(data, "hospitalId")}
                  onChange={(e) => {
                    addObject({ hospitalId: e.target.value });
                    getPhysicians({ hospitalId: e.target.value });
                  }}
                  className={`form-select p-2 ${returnErrorColor(
                    errors?.hospitalId
                  )}`}
                >
                  <option value={""}>Select</option>
                  {hospitals?.map((e, i) => (
                    <option value={e?.hospitalId} key={i}>
                      {e?.hospitalName}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <input
                type="text"
                name="appointmentName"
                value={returnData(data, "appointmentName")}
                onChange={handleChange("appointmentName")}
                placeholder="Reason For Appointment"
                className={`form-control p-2 ${returnErrorColor(
                  errors?.appointmentName
                )}`}
              />
            </div>
            <div className="col-md-6 mb-2">
              <input
                type="text"
                name="firstName"
                value={returnData(data, "firstName")}
                onChange={handleChange("firstName")}
                placeholder="First Name"
                className={`form-control p-2 ${returnErrorColor(
                  errors?.firstName
                )}`}
              />
            </div>
            <div className="col-md-6 mb-2">
              <input
                type="text"
                name="lastName"
                value={returnData(data, "lastName")}
                onChange={handleChange("lastName")}
                placeholder="Last Name"
                className={`form-control p-2 ${returnErrorColor(
                  errors?.lastName
                )}`}
              />
            </div>
            <div className="col-md-6 mb-2">
              <input
                type="text"
                name="email"
                value={returnData(data, "email")}
                onChange={handleChange("email")}
                placeholder="Email"
                className={`form-control p-2 ${returnErrorColor(
                  errors?.email
                )}`}
              />
            </div>
            <div className="col-md-6 mb-2">
              <input
                type="text"
                name="mobile"
                value={returnData(data, "mobile")}
                onChange={handleChange("mobile")}
                placeholder="Mobile Number"
                className={`form-control p-2 ${returnErrorColor(
                  errors?.mobile
                )}`}
              />
            </div>
          </div>
        </div>

        <div
          className="text-white col-md-6 bg-white mt-2 p-3 mb-1  border border-muted rounded  "
          style={{
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          {physician?.length > 0 ? (
            <>
              {physician.map((e, i) => {
                return (
                  <div
                    onClick={() => addObject({ physicianId: e?.userId })}
                    className="d-flex justify-content-around m-1 p-2 rounded border border-info "
                    style={{
                      backgroundColor:
                        data?.physicianId == e?.userId
                          ? css.themeColor
                          : css.themeColor2,
                    }}
                  >
                    <div className="col-md-2  d-flex justify-content-center align-items-center  ">
                      <FaRegUserCircle size={50} />
                    </div>
                    <div className="col-md-4 text-center  ">
                      <small>Clinician Name</small>
                      <div className="fs-8 fw-semibold" key={i}>
                        {e?.firstname + " " + e?.lastName}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <small>Service</small>

                      <div className="fs-8 fw-semibold">
                        {e?.specialityName}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <small>Status</small>
                      <div
                        className="fs-8 fw-semibold"
                        style={{
                          color: e?.status == "Active" ? "green" : "red",
                        }}
                        key={i}
                      >
                        {e?.status}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              <div
                className="text-black d-flex justify-content-center  align-items-center "
                style={{ height: "100%" }}
              >
                <div className="col-md-12 text-center">
                  <img
                    src={stethlogp}
                    className="border border-none rounded  p-2 "
                  />
                  <div className="col-md-12 text-center fs-6 fw-bold">
                    No Clinician Available
                  </div>
                  <div
                    className="col-md-12 text-center"
                    style={{ color: css.themeColor2 }}
                  >
                    Select Different Service
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="col-md-12 bg-white border border-muted rounded  "
        style={{ height: "51vh" }}
      >
        {returndates()?.length > 0 ? (
          <div className=" ">
            <div className="d-flex justify-content-around p-3 m-1">
              <div
                className="col-md-2 border text-center  p-1 m-1 rounded"
                style={{
                  height: "35vh",
                  overflowY: "scroll",
                }}
              >
                Availabile Dates
                {returndates()?.map((e, i) => (
                  <div
                    onClick={() => addObject({ date: e })}
                    className="border border m-1 rounded"
                    style={{
                      cursor: "pointer",
                      backgroundColor: data?.date == e ? css.themeColor : "",
                      color: data?.date == e ? "white" : "",
                    }}
                    key={i}
                  >
                    {e}
                  </div>
                ))}
              </div>
              <div
                className="col-md-10 border  text-center p-1 m-1 rounded "
                style={{
                  height: "35vh",
                  overflowY: "scroll",
                }}
              >
                Available Time
                <div className="d-flex flex-wrap  m-1 justify-content-around">
                  {returnTimings()?.map((e, i) => (
                    <div
                      className="p-1 col-md-3 border border rounded d-flex flex-wrap  m-1 justify-content-around  "
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          data?.startActivity == e.startActivity
                            ? css.themeColor
                            : "",
                        color:
                          data?.startActivity == e.startActivity ? "white" : "",
                      }}
                      onClick={() => addObject({ startActivity: e.startActivity })}
                      key={i}
                    >
                      {moment(e.startActivity)?.format("hh:mm A")}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className="text-black d-flex justify-content-center  align-items-center "
              style={{ height: "100%" }}
            >
              <div className="col-md-12 text-center">
                <img
                  src={calendar}
                  className="border border-none rounded  p-2 "
                />
                <div className="col-md-12 text-center fs-6 fw-bold">
                  No Date And Time Available
                </div>
                <div
                  className="col-md-12 text-center"
                  style={{ color: css.themeColor2 }}
                >
                  Select Different Clinician
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SearchForAppointment;
