import * as SDK from "mirrorfly-sdk";
import { useEffect } from "react";
import { mirrorFlyLicenseKey } from "../../../ConfigFiles/Consts";
import { useDispatch } from "react-redux";
import { addMessages, changeConnectionStatus } from "../../../Store/action";
import { getUserfromSS } from "../../../ConfigFiles/SmallFunctions";
import { useState } from "react";

const MirrorFly = () => {
  const dispatch = useDispatch();

  const [initialized,setInitialized] = useState(false)
  const connectionListener = (res) => {
    console.log(res, "res");
  };
  
  const presenceListener = (res) => {};
  const friendsListListener = (res) => {};
  const userProfileListener = (res) => {};
  const messageListener = (res) => {
    console.log(res,"here")
    if (res?.chatType == "chat") {
        console.log(res,"chat")
      dispatch(addMessages([res] || []));
    }
  };
  const replyMessageListener = (res) => {};
  const favouriteMessageListener = (res) => {};
  const groupProfileListener = (res) => {};
  const groupMsgInfoListener = (res) => {};
  const mediaUploadListener = (res) => {};
  const mediaDownloadListener = (res) => {};
  const blockUserListener = (res) => {};
  const singleMessageDataListener = (res) => {};
  const muteChatListener = (res) => {};
  const archiveChatListener = (res) => {};
  const userDeletedListener = (res) => {};
  const adminBlockListener = (res) => {};

  const initializeMirrorFlySDK = async () => {
    const initializeObj = {
      licenseKey: mirrorFlyLicenseKey,
      callbackListeners: {
        connectionListener,
        presenceListener,
        friendsListListener,
        userProfileListener,
        messageListener,
        replyMessageListener,
        favouriteMessageListener,
        groupProfileListener,
        groupMsgInfoListener,
        mediaUploadListener,
        mediaDownloadListener,
        blockUserListener,
        singleMessageDataListener,
        muteChatListener,
        archiveChatListener,
        userDeletedListener,
        adminBlockListener,
      },
    };
    await SDK.initializeSDK(initializeObj);
  };

  const registerUserAndEstablishConnection = async () => {
    let res = await SDK.register(getUserfromSS("userId"), true);
    console.log(res, "res");
    if (res?.statusCode == 200) {
      let connection = await SDK.connect(res?.data?.username, res?.data?.password);
      if (connection?.statusCode == 200) {
        dispatch(changeConnectionStatus(true))
      }
      console.log(connection, "conne ction");
    }
  };

  useEffect(() => {
    registerUserAndEstablishConnection();
  }, []);

  useEffect(() => {
    initializeMirrorFlySDK();
  }, []);

  return <div></div>;
};

export default MirrorFly;
