import React, { useEffect, useState } from "react";
import { getById, getfList, getList, save } from "../../ConfigFiles/SharedFunctions";
import { css, urls } from "../../ConfigFiles/Consts";
import { decryptData, getUserfromSS, returnData, returnDate, returnErrorColor, returningValue, sortingList } from "../../ConfigFiles/SmallFunctions";
import { useFormValidation } from "../../Validations/useFormValidations";
import { useNavigate, useParams } from "react-router-dom";
import { genderList, yesOrNoList } from "../../ConfigFiles/ConstList";
import Modal from "react-bootstrap/Modal";
import Raz from "../../ThirdPartyIntegrations/Razorpay/Razorpay";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { sendWhatsappMessage } from "../../ThirdPartyIntegrations/Whatsapp/Whatsapp";
import { BiArrowBack } from "react-icons/bi";

const CarePlans = () => {
  const navigate = useNavigate();
  const { appointmentId } = decryptData(useParams());
  const [hospitals, setHospitals] = useState([]);
  const [packages, setPackages] = useState([]);
  const [model, setModel] = useState({});
  const [update, setUpdate] = useState(Date.now());

  const [broken, setBroken] = useState(window.matchMedia("(max-width: 768px)").matches);
  const hospitalsGetAll = async () => {
    let res = await getList(urls?.hospital?.getAllHospitals);
    setHospitals(res);
  };
  const changeModel = (name, q) => {
    setModel({
      ...model,
      [name]: model?.[name] == true ? false : true,
    });
    // setUpdatingData(q || {});
  };

  const changeandUpdateModel = (name, q) => {
    setModel({
      ...model,
      [name]: model?.[name] == true ? false : true,
    });
    // setUpdatingData(q || {});
    setUpdate(Date.now());
  };

  const submit = async () => {
    if (getUserfromSS("roleId") == 5) {
      data["patientId"] = getUserfromSS("userId");
    }
    // let endA = new Date(data?.startTime);
    // endA.setMinutes(endA.getMinutes() + 30);
    // data["endTime"] = moment(endA).format().split("+")?.[0];
    // data["preferredDate"] = (data?.["preferredDate"] || [])?.map((e) => ({ appointmentDate: e?.toString() }));
    data["status"] = "Requested";
    data["patientAddress"] = { addressType: data?.addressType, nationalAddress: data?.nationalAddress, city: data?.city, pinCode: data?.pinCode, cluster: data?.cluster, neighbourhoodDistrict: data?.neighbourhoodDistrict };
    // let body = { key: "appointmentInfo", appointmentInfo: data };
    // let res = await save(urls?.appointment?.saveAppointment, body);
    let res = await save(urls?.appointment?.savePackageAppointment, data);
    if (res?.data?.status == true) {
      // let msg = `Your appointment is Scheduled Successfully with Dr. ${physicians?.filter((v)=>v?.physicianId == data?.physicianId)?.[0]?.name}`
      // await sendWhatsappMessage(patients?.filter((v)=>v?.userId == data?.patientId)?.[0]?.mobile,msg)
      navigate(`/`);
    }
  };

  const makePayment = (d) => {
    Object.assign(data, { paymentType: "Razorpay", paymentStatus: "Paid" });
    console.log(d, "dd");
    changeModel("payment");
    submit();
  };

  const { data, errors, setV, addObject, handleChange, handleSubmit, handleDateChange, formChange } = useFormValidation({
    initialValues: {},
    validationSchema: {},
    submit: submit,
  });

  const changePatientModel = (data) => {
    setModel({ patient: model?.patient == true ? false : true });
    if (data) {
      addObject({ patientId: data?.["userId"] });
      setUpdate(Date.now());
    }
  };

  const getPackages = async () => {
    let res = await getById(urls.package.getAllWithoutToken, { id: data?.hospitalId });
    let r = res?.appointmentPackage?.map((e) => ({ ...e, appointmentPackageTrans: res?.appointmentPackageTrans?.filter((v) => v?.appointmentPackageId == e?.appointmentPackageId) }));
    console.log(r, "r");
    setPackages(r);
  };

  useEffect(() => {
    setUpdate(Date.now());
  }, [broken]);

  useEffect(() => {
    data?.hospitalId > 0 && getPackages();
  }, [data?.hospitalId]);

  useEffect(() => {
    hospitalsGetAll();
  }, []);
  return (
    <>
      <div className="py-2">
        {model?.payment == true && <Raz parentCallback={makePayment} amount={packages?.filter((v) => v?.appointmentPackageId == data?.appointmentPackageId)?.[0]?.charges * 100 || 100 * 100} />}

        <div className="d-flex text-center justify-content-between p-2" style={{ height: "5vh" }}>
          <BiArrowBack size={20} style={{ cursor: "pointer" }} className="" onClick={() => navigate("/")} />
          {/* <div className=" fw-bold BookAppointment ">  Appointment Booking</div> */}
        </div>
        <div className="row mx-1 gap-4">
          <div className="col-md-6 col-lg-4 row col-xl-4  bg-white mt-2  border-muted rounded shadow-lg" style={{ maxHeight: "91vh", overflow: "auto" }}>
            <div className="col-md-12  p-2 ">
              <label className="text-size">Select Location</label>
              <select name="labPackageId" value={returnData(data, "hospitalId")} onChange={handleChange("hospitalId")} className={`form-select p-2 ${returnErrorColor(errors?.hospitalId)}`}>
                <option value={""}>Select</option>
                {hospitals?.map((e, i) => (
                  <option value={e?.hospitalId} key={i}>
                    {e?.hospitalName}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-md-6  p-2">
              <label className="text-size">First Name </label>
              <input type="text" name="firstName" value={returnData(data, "firstName")} onChange={formChange("firstName", "Alphabet")} placeholder="First Name" className={`form-control p-2     ${returnErrorColor(errors?.firstName)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Last Name </label>
              <input type="text" name="lastName" value={returnData(data, "lastName")} onChange={formChange("lastName", "Alphabet")} placeholder="Last Name" className={`form-control p-2     ${returnErrorColor(errors?.lastName)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Gender </label>
              <select type="text" name="gender" value={returnData(data, "gender")} onChange={handleChange("gender")} placeholder="Gender" className={`form-control p-2 ${returnErrorColor(errors?.gender)}`}>
                <option value={""}>Select</option>
                {genderList?.map((e, i) => (
                  <option key={i}>{e}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Mobile </label>
              <input type="text" name="mobile"
                maxLength={15}
                value={returningValue(data?.["mobile"], "Phone")}
                onChange={formChange("mobile", "Phone")}

                placeholder="Mobile" className={`form-control p-2     ${returnErrorColor(errors?.mobile)}`} />
            </div>
            <div className="col-md-12  p-2">
              <label className="text-size">Email</label>
              <input type="text" name="email" value={returnData(data, "email")} onChange={handleChange("email")} placeholder="Email" className={`form-control p-2     ${returnErrorColor(errors?.email)}`} />
            </div>
            <div className="col-md-12 mb-2 p-2">
              <label className="text-size">
                Date Of Birth
              </label>
              <DatePicker
                className={`form-control border py-2 ${returnErrorColor(errors?.dob)}`}
                selected={data?.dob ? new Date(data.dob) : ""}
                minDate={new Date(1900, 1, 1)}
                maxDate={new Date()}
                onChange={(e) => {
                  handleDateChange(e, "dob");
                }}
                autoComplete="off"
                name="dob"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                popperClassName="react-datepicker-popper"
                showMonthDropdown
                showYearDropdown
                style={{ fontSize: "20px" }}
                dropdownMode="select"
              />
            </div>
            <div className="col-md-6 p-2">
              <label className="text-size">Prefered Date</label>
              <DatePicker
                className="form-control col-md-6 border py-2 "
                minDate={new Date()}
                maxDate={new Date(2049, 1, 1)}
                autoComplete="off"
                selected={returnDate(data?.["preferredDate"])}
                onChange={(e) => handleDateChange(e, "preferredDate")}
                name="preferredDate"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                popperClassName="react-datepicker-popper"
                showMonthDropdown
                showYearDropdown
                style={{
                  fontSize: "20px",
                  outline: "0px",
                  borderWidth: "0 0 2px",
                  borderColor: "black",
                  width: "250px",
                }}
                dropdownMode="select"
              />
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Teleconsultation </label>
              <select name="teleConsultation" value={returnData(data, "teleConsultation")} onChange={handleChange("teleConsultation")} className={`form-select p-2 ${returnErrorColor(errors?.teleConsultation)}`}>
                <option value={""}>Select</option>
                {yesOrNoList?.map((e, i) => (
                  <option key={i}>{e}</option>
                ))}
              </select>
            </div>
            <div className="col-md-12  p-2">
              <label className="text-size">Reason for appointment </label>
              <input type="text" name="appointmentName" value={returnData(data, "appointmentName")} onChange={handleChange("appointmentName")} placeholder="Reason" className={`form-control p-2     ${returnErrorColor(errors?.appointmentName)}`} />
            </div>

            {/* <div className="col-md-6  p-2">
              <label className="text-size"> Address type </label>
              <input type="text" name="addressType" value={returnData(data, "addressType")} onChange={formChange("addressType", "Alphabet")} placeholder="  Address type" className={`form-control p-2     ${returnErrorColor(errors?.addressType)}`} />{" "}
            </div> */}
            <div className="col-md-6  p-2">
              <label className="text-size">National Address </label>
              <input type="text" name="nationalAddress" value={returnData(data, "nationalAddress")} onChange={formChange("nationalAddress", "Alphabet")} placeholder=" National Address" className={`form-control p-2     ${returnErrorColor(errors?.nationalAddress)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">City </label>
              <input type="text" name="city" value={returnData(data, "city")} onChange={formChange("city", "Alphabet")} placeholder="City" className={`form-control p-2     ${returnErrorColor(errors?.city)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Cluster</label>
              <input type="text" name="cluster" value={returnData(data, "cluster")} onChange={handleChange("cluster")} placeholder="Cluster" className={`form-control p-2     ${returnErrorColor(errors?.cluster)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size"> Neighbourhood/District</label>
              <input type="text" name="neighbourhoodDistrict" value={returnData(data, "neighbourhoodDistrict")} onChange={formChange("neighbourhoodDistrict", "Alphabet")} placeholder=" Neighbourhood/District" className={`form-control p-2     ${returnErrorColor(errors?.neighbourhoodDistrict)}`} />{" "}
            </div>

            {/* <div className="col-md-6  p-2">
              <label className="text-size">Zip code </label>
              <input type="text" name="pinCode" value={returnData(data, "pinCode")} maxLength={5} onChange={handleChange("pinCode")} placeholder="Zip code" className={`form-control p-2     ${returnErrorColor(errors?.pinCode)}`} />{" "}
            </div> */}
          </div>
          <div className="col-md-6 col-lg-8 col-xl-8      mt-2   border-muted   " style={{ height: "90vh", overflowY: "scroll" }}>
            {packages?.length > 0 ? (
              <>
                {packages?.map((e, i) => {
                  return (
                    <div className=" d-flex  mx-2" style={{ maxHeight: "auto" }}>
                      <div
                        onClick={() => addObject({ appointmentPackageId: e?.appointmentPackageId })}
                        className=" w-100 justify-content-between my-1 p-2 rounded border border text-black pointer "
                        style={{
                          backgroundColor: data?.appointmentPackageId == e?.appointmentPackageId ? css.Booked : css.Preadmission,
                        }}
                      >
                        <div className="  d-flex justify-content-between">
                          <div className=" col-md-6 fs-8 fw-semibold " key={i}>
                            {e?.packageName}
                          </div>
                          <div className="col-md-6 text-end">
                            <small>
                              Charges: <span className="fs-8 fw-semibold"> {e?.charges || "0"}</span>
                            </small>
                          </div>
                        </div>
                        <div className=" d-flex justify-content-between mb-1 align-items-center">
                          <div className="col-md-9 ">
                            Description:<span className="fs-8 fw-bold">{e?.description}</span>{" "}
                          </div>
                          <div className="col-md-3 text-end">
                            {data?.appointmentPackageId == e?.appointmentPackageId && (
                              <div
                                className=" rounded text-center p-2 "
                                style={{
                                  backgroundColor: data?.appointmentPackageId == e?.appointmentPackageId ? "#03469E" : css.Preadmission,
                                  color: "white",
                                }}
                                onClick={() => {
                                  if (data?.paymentStatus == "Paid") {
                                    submit();
                                  } else {
                                    changeModel("payment");
                                  }
                                }}
                              >
                                Book Appointment
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="  d-flex gap-4  p-1  mb-1" style={{ width: "100%", overflowX: "scroll" }}>
                          {e?.appointmentPackageTrans?.map((v, i) => (
                            <div className=" col-md-4 border rounded mb-1 ">
                              <div className=" ">
                                Service:<span className="fs-8 fw-semibold">{v?.serviceName}</span>
                              </div>
                              <div className=" ">
                                Visits:<span className="fs-8 fw-semibold">{v?.visits}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div></div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="steth2 d-flex  justify-content-center" style={{ height: "100%" }}></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarePlans;
