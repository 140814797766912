import React from 'react'
import { css } from '../../ConfigFiles/Consts'

const CusomerCare = () => {
  return (
    <div style={{backgroundColor:css.Preadmission2,}} className='h-100 w-100'>
      Customer care
    </div>
  )
}

export default CusomerCare
