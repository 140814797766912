import { useState } from "react";
let isSubmitted = false;
let newErrors = {};
let hasErrors = false;
export const useFormValidation = (metadata) => {
  const [data, setData] = useState(metadata.initialValues || {});
  const [errors, setErrors] = useState({});

  const handleChange = (name) => (e) => {
    if (name) {
      let str = e.target.value
        .replace(/(^\s*)|(\s*$)/, "")
        .replace(/[ ]{2,}/gi, " ")
        .replace(/\n +/, "\n");
      console.log(str, "str")
      setData({
        ...data,
        [name]: str,
      });

      submittingData(name, str);
    }
  };
  const handleChangeLocation = (lat, lng) => {
    setData({ ...data, [lat]: lat, [lng]: lng });
  }

  const handleChange1 = (name, val) => {
    setData({ ...data, [name]: val });
  };
  const handleDataChange = (name, value) => {
    if (name) {
      setData({
        ...data,
        [name]: value,
      });

      submittingData(name, value);
    }
  };

  const addItem = (key, item) => {
    if (data[key] && data[key]?.length > 0) {
      setData({ ...data, [key]: [...data?.[key], item || {}] });
    } else {
      setData({ ...data, [key]: [item || {}] });
    }
  };

  const removeItem = (name, index) => {
    setData({ ...data, [name]: data[name]?.filter((v, i) => i != index) });
  };

  const resetFormData = (name) => {
    setData({ ...data, [name]: [{}] });
  };

  const returningData = (value, type) => {
    console.log(value, "vvvv");
    if (value != undefined && value != null && value != "") {
      switch (type) {
        case "Alphabet":
          return (
            value
              ?.replace(/(^\s*)|(\s*$)/, "")
              .replace(/[ ]{2,}/gi, " ")
              .replace(/\n +/, "\n")
              .replace(/[^a-zA-Z ]/g, "")
              .charAt(0)
              .toUpperCase() + value.slice(1)
          );
        case "AlphaNumaric":
          return (
            value
              ?.replace(/(^\s*)|(\s*$)/, "")
              ?.replace(/[ ]{2,}/gi, " ")
              ?.replace(/\n +/, "\n")
              ?.replace(/[^a-zA-Z0-9 ]/g, "")
              .charAt(0)
              .toUpperCase() + value.slice(1)
          );
        case "Number":
          return parseInt(value?.replace(/[^0-9\.]/g, ""));
        // case "Decimal": return parseFloat(value?.replace(/[^0-9\.]/g, "")).toFixed(2);
        case "NumberString":
          return value?.replace(/[^0-9\.]/g, "");
        case "Decimal":
          // return value?.replace(/[^0-9.]/g, "")?.replace(/(\.\d{2})\d+/, "$1");
          return value?.replace(/[^0-9.]/g, "")
            ?.replace(/(\..*)\./g, "$1")
            ?.replace(/(\.\d{2})\d+/, "$1");
        case "Phone":
          let res = value?.replace("+91", "")?.replace(/[^0-9]/g, "")?.replace(/\s+/g, '');
          let l = res?.length;
          if (l <= 5) return res?.replace(/(\d{1})/, "+91 $1", "")
          else if (l > 5 && l <= 10) return res?.replace(/(\d{5})(\d{1})/, "+91 $1 $2", "")
          break
        // return value?.replace(/(\d{2})(\d{3})(\d{4})/, '+966 $1 $2 $3', "");
        // let ph = value?.replace(/[^0-9]/g, "");
        // if (ph.length >= 10) {
        //   return ph?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        // } else {
        //   return ph;
        // }
        case "Date":
          let date = "";
          if (value != null) {
            value?.setMinutes(0);
            value?.setHours(0);
            date = new Date(value?.setMinutes(value?.getTimezoneOffset() < 0 ? -value?.getTimezoneOffset() : value?.getTimezoneOffset()));
          } else {
            date = "";
          }
          return date;
        case "":
          return value;
        default:
          return value;
      }
    } else {
      return "";
    }
  };

  const formChange = (name, type) => (e) => {
    let d = returningData(e?.target?.value || "", type);
     setData({ ...data, [name]: d });
    submittingData(name, d);
  };

  const writeData = (index, name, key, type) => (e) => {
    e.preventDefault();
    let d = returningData(e.target.value, type);
    if (name) {
      let fd = data[name];
      let present = fd[index];
      present[key] = d;
      fd[index] = present;
      setData({ ...data, [name]: fd });
    }
  };

  const writeAddObject = (index, name, obj) => {
    if (name) {
      let fd = data[name];
      let present = fd[index];
      // present[key] = e.target.value
      present = { ...present, ...obj };
      fd[index] = present;
      setData({ ...data, [name]: fd });
    }
  };

  const writeDate = (index, name, key) => (e) => {
    let str;
    if (name) {
      if (e != null) {
        e?.setMinutes(0);
        e?.setHours(0);
        str = new Date(e?.setMinutes(e?.getTimezoneOffset() < 0 ? -e?.getTimezoneOffset() : e?.getTimezoneOffset() || 0));
      } else {
        str = "";
      }
    }
    if (name) {
      let fd = data[name];
      let present = fd[index];
      present[key] = str;
      fd[index] = present;
      setData({ ...data, [name]: fd });
    }
  };

  const handleCheckbox = (name) => (e) => {
    // e.preventDefault();
    setData({
      ...data,
      [name]: e.target.checked ? e.target.value : "",
    });
  };

  const handleClear = (name, key) => (e) => {
    setData({ ...data, [name]: e.target.checked ? e.target.value : "" });
    if (data[name]) {
      setData({ ...data, [key]: "", [name]: "" });
    }
  };

  const handleAlphabetChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value
        .replace(/(^\s*)|(\s*$)/, "")
        .replace(/[ ]{2,}/gi, " ")
        .replace(/\n +/, "\n")
        .replace(/[^a-zA-Z ]/g, "");
      setData({
        ...data,
        [name]: str.charAt(0).toUpperCase() + str.slice(1),
      });
      submittingData(name, str);
    }
  };

  const handleAlphanumaricChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value
        .replace(/(^\s*)|(\s*$)/, "")
        .replace(/[ ]{2,}/gi, " ")
        .replace(/\n +/, "\n")
        .replace(/[^a-zA-Z0-9 ]/g, "");
      setData({
        ...data,
        [name]: str.charAt(0).toUpperCase() + str.slice(1),
      });
      submittingData(name, str);
    }
  };

  const handlePhoneChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value.replace(/[^0-9]/g, "");
      if (str.length >= 10) {
        str = str.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };

  const handleNumberChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value.replace(/[^0-9\.]/g, "");
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };

  const handleCustomChange = (name, limit) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value.replace("/ [d]{1} /", "");
      str = str <= limit ? str : limit;
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };

  const handlefeeChange = (name) => (e) => {
    let str = e.target.str;
    if (name) {
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };

  const handlefChange = (name) => (e) => {
    e.preventDefault();
    setData({});
    if (name) {
      setData({
        [name]: e.target.value
          .replace(/(^\s*)|(\s*$)/, "")
          .replace(/[ ]{2,}/gi, " ")
          .replace(/\n +/, "\n"),
      });
      if (isSubmitted) {
        validateFormControl(name, e.target.value);
        if (errors && errors[name] && !newErrors[name]) {
          setErrors({
            ...errors,
            [name]: "",
          });
        } else {
          setErrors({
            ...errors,
            ...newErrors,
          });
        }
        newErrors = {};
      }
    }
  };

  const handleMultiple = (name, keys) => (e) => {
    e.preventDefault();
    let idata;
    if (e.target.value != "") {
      idata = JSON.parse(e.target.value);
    }
    if (e.target.value == "") {
      setData({
        ...data,
        [keys[0] + "1"]: "",
        [keys[0]]: "",
        [keys[1]]: "",
        [keys[2]]: "",
      });
    } else {
      if (name == "cpt") {
        setData({
          ...data,
          [keys[0] + "1"]: JSON.stringify(idata),
          [keys[0]]: idata.cptCodesID,
          [keys[1]]: idata.cptDesc,
          [keys[2]]: idata.inNetworkRate,
        });
      }
      if (name == "otherProcedure") {
        setData({
          ...data,
          [keys[0] + "1"]: JSON.stringify(idata),
          [keys[0]]: idata.id,
          [keys[1]]: idata.description,
          [keys[2]]: idata.fee,
        });
      }
      if (name == "icd10") {
        setData({
          ...data,
          [keys[0] + "1"]: JSON.stringify(idata),
          [keys[0]]: idata.icdTenID,
          [keys[1]]: idata.icdDesc,
          [keys[2]]: idata.rate,
        });
      }
    }
  };

  const handleUChange = (name) => (e) => {
    // e.preventDefault();
    if (name) {
      let str = e.target.value
        .replace(/(^\s*)|(\s*$)/, "")
        .replace(/[ ]{2,}/gi, " ")
        .replace(/\n +/, "\n");
      setData({
        ...data,
        [name]: str.charAt(0).toUpperCase() + str.slice(1),
      });
      submittingData(name, str);
    }
  };

  const handleUpperChange = (name) => (e) => {
    e.preventDefault();

    if (name) {
      let str = e.target.value
        .replace(/(^\s*)|(\s*$)/, "")
        .replace(/[ ]{2,}/gi, " ")
        .replace(/\n +/, "\n");
      setData({
        ...data,
        [name]: str.toUpperCase(),
      });
      submittingData(name, str);
    }
  };

  const handleEmailChange = (name) => (e) => {
    e.preventDefault();
    if (name) {
      let str = e.target.value
        .replace(/(^\s*)|(\s*$)/, "")
        .replace(/[ ]{1,}/gi, "")
        .replace(/\n +/, "\n");
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };
  const handleDateChange = (e, name) => {
    let str;
    if (name) {
      if (e != null) {
        e?.setMinutes(0);
        e?.setHours(0);
        str = new Date(e?.setMinutes(e?.getTimezoneOffset() < 0 ? -e?.getTimezoneOffset() : e?.getTimezoneOffset()));
      } else {
        str = "";
      }
      console.log(str, name);
      setData({
        ...data,
        [name]: str,
      });
      submittingData(name, str);
    }
  };

  const handleRateChange = (e, name) => {
    if (name) {
      setData({
        ...data,
        [name]: e,
      });
      submittingData(name, e);
    }
  };

  const handleTimeChange = (e, name) => {
    // e.preventDefault();
    if (name) {
      setData({
        ...data,
        [name]: e,
      });
      submittingData(name, e);
    }
  };

  const setV = (v) => {
    setData(v);
  };

  const setTwoValues = (u, v) => {
    setData(Object.assign(u, v));
    // setData({
    //   ...v,
    //   ...u
    // });
  };

  const addObject = (u) => {
    setData((prev) => ({
      ...prev,
      ...u,
    }));
  };

  const setMultipleValues = (array) => {
    setData(
      Object.assign(
        array.map((e) => {
          return e;
        })
      )
    );
    // setData({
    //   ...v,
    //   ...u
    // });
  };

  const addValues = (v) => {
    setData({
      ...data,
      v,
    });
  };

  const handleToggleChange = (name, value) => {
    if (name) {
      setData({
        ...data,
        [name]: value,
      });
      submittingData(name, value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isSubmitted = true;
    validateForm();
    if (!hasErrors) {
      metadata.submit();
    }
  };

  const validateForm = () => {
    for (const key in metadata.validationSchema) {
      validateFormControl(key, data?.[key]);
    }
    setErrors(newErrors);
    var size = Object.keys(newErrors).length;
    if (size) {
      hasErrors = true;
    } else {
      hasErrors = false;
    }
    newErrors = {};
  };

  const submittingData = (name, value) => {
    if (isSubmitted) {
      validateFormControl(name, value);

      if (errors && errors[name] && !newErrors[name]) {
        setErrors({
          ...errors,
          [name]: "",
        });
      } else {
        setErrors({
          ...errors,
          ...newErrors,
        });
      }
      newErrors = {};
    }
  };
  const handleSelectMultiple = (name) => (selectedValues) => {
    console.log(selectedValues, "selectedValues", name);
    setData({
      ...data,
      [name]: selectedValues,
    });
    submittingData(name, selectedValues);
  };

  const validateFormControl = (key, value) => {
    const schema = metadata.validationSchema;
    const validationFormControl = schema?.[key];
    if (validationFormControl?.required && !value) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.required?.message,
      };
    } else if (validationFormControl?.minlength && value.length < validationFormControl?.minlength?.value) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.minlength?.message,
      };
    } else if (validationFormControl?.maxlength && value.length > validationFormControl?.maxlength?.value) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.maxlength?.message,
      };
    } else if (validationFormControl?.pattern && !validationFormControl.pattern?.value?.test(value)) {
      newErrors = {
        ...newErrors,
        [key]: validationFormControl?.pattern?.message,
      };
    }
  };

  return {
    data,
    errors,
    isSubmitted,
    addValues,
    addObject,
    addItem,
    writeData,
    writeAddObject,
    writeDate,
    removeItem,
    resetFormData,
    handlefChange,
    handlefeeChange,
    handleMultiple,
    handleUChange,
    handleChange,
    handleChange1,
    handleSubmit,
    setV,
    handleDateChange,
    handleEmailChange,
    handleRateChange,
    handleUpperChange,
    handleTimeChange,
    handlePhoneChange,
    handleAlphabetChange,
    handleNumberChange,
    handleToggleChange,
    handleAlphanumaricChange,
    handleCheckbox,
    handleClear,
    handleCustomChange,
    handleDataChange,
    setMultipleValues,
    setTwoValues,
    handleSelectMultiple,
    formChange,
    handleChangeLocation
  };
};
