import React, { useEffect, useState, useCallback } from "react";
import { useFormValidation } from "../../Validations/useFormValidations";
import "../../App.css";
import { checkLoginStatus, encryptData, navigationFn, returnErrorColor } from "../../ConfigFiles/SmallFunctions";
import { useNavigate } from "react-router-dom";
import { Add, notify, save } from "../../ConfigFiles/SharedFunctions";
import { css, urls } from "../../ConfigFiles/Consts";
import myimage from "../../Images/Doctor.png";
import hide from "../../Images/hide.png";
import { CiUnread, CiRead } from "react-icons/ci";
import view from "../../Images/view.png";
import side from "../../Images/side.png";
// import bantia from "../../Images/bantia.png";
import svhealth1 from "../../Images/LOTS_HR_white.png";
import { useTranslation, Trans } from "react-i18next";
import { login } from "../../ConfigFiles/ValidationSchemas";
import loginImg from "../../Images/Rectangle 10.png";

const Login = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState(true);

  const [isRtl, setIsRtl] = useState(false);
  const { t, i18n } = useTranslation();
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const handleBeforeInstallPrompt = useCallback((e) => {
    // Prevent the mini-infobar from appearing on mobile
    e.preventDefault();

    // Store the event for later use
    setDeferredPrompt(e);
  }, []);

  const handleAddToHomeScreenClick = async () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();

      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === "accepted") {
        alert("User accepted the install prompt");
      } else {
        alert("User dismissed the install prompt");
      }

      setDeferredPrompt(null);
    } else {
      alert("Install prompt is not available");
    }
  };

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstallPrompt);
    };
  }, [handleBeforeInstallPrompt]);

  const changingPasswordtype = () => {
    setPassword(!password);
  };

  const submit = async () => {
    let res = await Add(urls?.auth?.login, data);
    if (res?.data?.status == true) {
      let roleId = res?.data?.data?.user?.roleId;
      notify(res?.data?.status, res?.data?.message);
      sessionStorage.setItem("token", res?.data?.data?.token);
      sessionStorage.setItem("user", JSON.stringify(res?.data?.data?.user));
      let route = navigationFn(res?.data?.data?.user);
      navigate(route);
    }
  };

  const forgotPassword = async () => {
    if (data?.email && data?.email != "") {
      let body = { serverName: window.location.hostname, email: data?.email };
      let res = await save(urls.auth.forgotPassword, body);
    } else {
      notify(false, "Please enter email");
    }
  };

  const { data, errors, handleChange, handleSubmit } = useFormValidation({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: login,
    submit: submit,
  });

  useEffect(() => {
    if (checkLoginStatus()) {
      let route = navigationFn(JSON.parse(sessionStorage.getItem("user")));
      console.log(route, "route");
      navigate(route);
    }
  }, []);

  return (
    <>
      {/* <button onClick={handleAddToHomeScreenClick}>Add to Home Screen</button> */}
      <div className="d-flex align-items-center justify-content-around mainLogin bg-white" style={{ height: "100vh" }}>
        <div className="col-md-6 col-xl-6  d-flex justify-content-center ">
          <form className="loginCard " onSubmit={handleSubmit}>
            <div className=" gap-4 p-4  d-flex flex-column  " style={{ borderRadius: "30px" }}>
              <div className="">
                <div className=" text-center fs-3 fw-bold text-primary">
                  <img src={svhealth1} className="" height={110} />
                </div>
                <div className="text-center fw-bold fs-4">Welcome back</div>
                <div className="text-center text-secondary">Start your Journey login into your account </div>
              </div>
              <div className="d-flex flex-column gap-1">
                <div className="  ">
                  <div className="text-black mb-1">{t("Email")}</div>
                  <input type="email" className={`form-control py-2 ${returnErrorColor(errors?.email)}`} name="email" onChange={handleChange("email")} placeholder="Enter your email address" />
                </div>
                {/* <div className="d-flex mr_25 ">{errors && errors.email && <p className=" text-danger">{errors.email}</p>}</div> */}
                <div className="">
                  <div className=" " style={{ position: "relative" }}>
                    <div className="text-black mb-1">{t("Password")}</div>
                    <input type={password ? "password" : "text"} className={`form-control py-2 ${returnErrorColor(errors?.password)}`} name="password" minlength="6" onChange={handleChange("password")} placeholder="Enter your password" />

                    <a className="passwordshowbtn" style={{ position: "absolute", top: "38px", right: "15px" }} onClick={changingPasswordtype}>
                      {/* <img src={password ? <CiUnread /> : view} alt="hide" /> */}
                      {password ? <CiUnread size={"20px"} color="black" /> : <CiRead size={"20px"} color="black" />}
                    </a>
                  </div>
                </div>
                {/* <div className="d-flex mr_25">{errors && errors.password && <p className=" text-danger">{errors.password}</p>}</div> */}
                <div className=" d-flex flex-column " style={{ marginTop: "0px" }}>
                  <div className="text-right rounded py-2  pointer" style={{ color: css?.newTheam }} onClick={forgotPassword}>
                    Forgot password?
                  </div>
                  <button className=" rounded p-2 w-100  addbtn" type="submit">
                    {t("Sign in")}
                  </button>


                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-6  ImageContainer">
          <img src={loginImg} style={{ height: "100vh", width: "100%" }} />
        </div>
      </div>
    </>
  );
};

export default Login;
