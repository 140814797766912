import React, { useEffect, useState } from "react";
import { getById, getList, save } from "../../ConfigFiles/SharedFunctions";
import { css, urls } from "../../ConfigFiles/Consts";
import { decryptData, returnData, returnDate, returnErrorColor, returningValue } from "../../ConfigFiles/SmallFunctions";
import { useFormValidation } from "../../Validations/useFormValidations";
import { useNavigate, useParams } from "react-router-dom";
import { genderList, yesOrNoList } from "../../ConfigFiles/ConstList";
import Raz from "../../ThirdPartyIntegrations/Razorpay/Razorpay";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiArrowBack } from "react-icons/bi";
import { BookingAppointment } from "../../ConfigFiles/ValidationSchemas";

const QuickAppointment = () => {
  const navigate = useNavigate();
  const [sepciality, setSpeciality] = useState([]);

  const [services, setServices] = useState([]);
  const [model, setModel] = useState({});
  const [update, setUpdate] = useState(Date.now());
  const [hospitals, setHospitals] = useState([]);
  const format = "YYYY-MM-DD";

  const [broken, setBroken] = useState(window.matchMedia("(max-width: 768px)").matches);

  const changeModel = (name, q) => {
    setModel({
      ...model,
      [name]: model?.[name] == true ? false : true,
    });
  };

  const changeandUpdateModel = (name, q) => {
    setModel({
      ...model,
      [name]: model?.[name] == true ? false : true,
    });
    setUpdate(Date.now());
  };

  const submit = async () => {
    let body = {
      ...data,
      patientAddress: { addressType: data?.addressType, nationalAddress: data?.nationalAddress, city: data?.city, pinCode: data?.pinCode, cluster: data?.cluster, neighbourhoodDistrict: data?.neighbourhoodDistrict },
      status: "Requested",
    };
    console.log(body, "body");
    let res = await save(urls?.appointment?.bookAppointment, body);
    if (res?.data?.status == true) {
      navigate("/login");
    }
  };
  const hospitalsGetAll = async () => {
    let res = await getList(urls?.hospital?.getAllHospitals);
    setHospitals(res);
  };

  const makePayment = (d) => {
    Object.assign(data, { paymentType: "Razorpay", paymentStatus: "Paid" });
    console.log(d, "dd");
    changeModel("payment");
    submit();
  };

  const { data, errors, setV, addObject, handleChange, handleSubmit, handleDateChange, formChange } = useFormValidation({
    initialValues: {},
    validationSchema: BookingAppointment,
    submit: () => {
      if (data?.paymentStatus == "Paid") {
        submit();
      } else {
        changeModel("payment");
      }
    },
  });

  const getServices = async () => {
    setServices(await getList(urls.service.getAllWithoutToken, { id: data?.hospitalId }));
  };

  const getSpecialityList = async () => {
    setSpeciality(await getList(urls?.speciality?.getAllWithoutToken, { id: data?.hospitalId }));
  };

  useEffect(() => {
    hospitalsGetAll();
  }, []);

  useEffect(() => {
    data?.hospitalId > 0 && getSpecialityList();
  }, [data?.hospitalId]);

  useEffect(() => {
    data?.hospitalId > 0 && getServices();
  }, [data?.hospitalId]);

  useEffect(() => {
    setUpdate(Date.now());
  }, [broken]);

  useEffect(() => {
    addObject({ physicianId: 0 });
  }, [data?.preferredDate]);

  return (
    <>
      <div className="py-2" style={{ backgroundColor: "#fff" }}>
        {model?.payment == true && <Raz parentCallback={makePayment} amount={services?.filter((v) => v?.serviceId == data?.serviceId)?.[0]?.charge * 100 || 100 * 100} />}

        <div className="d-flex text-center justify-content-between p-2" style={{ height: "5vh" }}>
          <BiArrowBack size={20} style={{ cursor: "pointer" }} className="" onClick={() => navigate("/")} />
          {/* <div className=" fw-bold BookAppointment ">  Appointment Booking</div> */}
        </div>
        <div className="row mx-1 gap-2">
          <div className="col-md-12 col-lg-4 col-xl-4  bg-white mt-2  border-muted rounded shadow-lg row" style={{ maxHeight: "91vh", overflow: "auto" }}>
            <div className="col-md-12  p-2 ">
              <label className="text-size">Select Location</label>
              <select name="labPackageId" value={returnData(data, "hospitalId")} onChange={(e) => addObject({ hospitalId: e?.target?.value, specialityId: "", serviceId: "" })} className={`form-select p-2 ${returnErrorColor(errors?.hospitalId)}`}>
                <option value={""}>Select</option>
                {hospitals?.map((e, i) => (
                  <option value={e?.hospitalId} key={i}>
                    {e?.hospitalName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-12  p-2 ">
              <label className="text-size">Select Service</label>
              <select name="specialityId" value={returnData(data, "specialityId")} onChange={(e) => addObject({ specialityId: e.target.value, serviceId: "" })} className={`form-select px-2 ${returnErrorColor(errors?.specialityId)}`}>
                <option value={""}>Select</option>
                {sepciality?.map((e, i) => (
                  <option value={e?.specialityId} key={i}>
                    {e?.specialityName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">First Name </label>
              <input type="text" name="firstName" value={returnData(data, "firstName")} onChange={formChange("firstName", "Alphabet")} placeholder="First Name" className={`form-control p-2     ${returnErrorColor(errors?.firstName)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Last Name </label>
              <input type="text" name="lastName" value={returnData(data, "lastName")} onChange={formChange("lastName", "Alphabet")} placeholder="Last Name" className={`form-control p-2     ${returnErrorColor(errors?.lastName)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Gender </label>
              <select type="text" name="gender" value={returnData(data, "gender")} onChange={handleChange("gender")} placeholder="Gender" className={`form-control p-2 ${returnErrorColor(errors?.gender)}`}>
                <option value={""}>Select</option>
                {genderList?.map((e, i) => (
                  <option key={i}>{e}</option>
                ))}
              </select>
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Mobile </label>
              {/* <input maxLength={"10"} type="text" name="mobile" value={returnData(data, "mobile")}
                onChange={formChange("mobile", "NumberString")} placeholder="Mobile"
                className={`form-control p-2 ${returnErrorColor(errors?.mobile)}`} /> */}
              <input type="text" name="mobile"
                maxLength={15}
                value={returningValue(data?.["mobile"], "Phone")}
                onChange={formChange("mobile", "Phone")}

                placeholder="Mobile" className={`form-control p-2     ${returnErrorColor(errors?.mobile)}`} />
            </div>
            <div className="col-md-12  p-2">
              <label className="text-size">Email</label>
              <input type="text" name="email" value={returnData(data, "email")} onChange={handleChange("email")} placeholder="Email" className={`form-control p-2     ${returnErrorColor(errors?.email)}`} />
            </div>
            <div className="col-md-12 mb-2 p-2">
              <label className="text-size">
                Date Of Birth
              </label>
              <DatePicker
                className={`form-control border py-2 ${returnErrorColor(errors?.dob)}`}
                selected={data?.dob ? new Date(data.dob) : ""}
                minDate={new Date(1900, 1, 1)}
                maxDate={new Date()}
                onChange={(e) => {
                  handleDateChange(e, "dob");
                }}
                autoComplete="off"
                name="dob"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                popperClassName="react-datepicker-popper"
                showMonthDropdown
                showYearDropdown
                style={{ fontSize: "20px" }}
                dropdownMode="select"
              />
            </div>
            <div className="col-md-6 p-2">
              <label className="text-size">Prefered Date</label>
              <DatePicker
                className={`form-control border py-2 ${returnErrorColor(errors?.preferredDate)}`}
                minDate={new Date()}
                maxDate={new Date(2049, 1, 1)}
                autoComplete="off"
                selected={returnDate(data?.["preferredDate"])}
                onChange={(e) => handleDateChange(e, "preferredDate")}
                name="preferredDate"
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/YYYY"
                popperClassName="react-datepicker-popper"
                showMonthDropdown
                showYearDropdown
                style={{
                  fontSize: "20px",
                  outline: "0px",
                  borderWidth: "0 0 2px",
                  borderColor: "black",
                  width: "250px",
                }}
                dropdownMode="select"
              />
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Teleconsultation </label>
              <select name="teleConsultation" value={returnData(data, "teleConsultation")} onChange={handleChange("teleConsultation")} className={`form-select p-2 ${returnErrorColor(errors?.teleConsultation)}`}>
                <option value={""}>Select</option>
                {yesOrNoList?.map((e, i) => (
                  <option key={i}>{e}</option>
                ))}
              </select>
            </div>
            <div className="col-md-12  p-2">
              <label className="text-size">Reason for appointment </label>
              <textarea type="text" name="appointmentName" value={returnData(data, "appointmentName")} onChange={formChange("appointmentName", "Alphabet")} placeholder="Reason" className={`form-control p-2     ${returnErrorColor(errors?.appointmentName)}`} />
            </div>
            {/* <div className="col-md-6  p-2">
              <label className="text-size"> Address type </label>
              <input type="text" name="addressType" value={returnData(data, "addressType")} onChange={formChange("addressType", "Alphabet")} placeholder="  Address type" className={`form-control p-2     ${returnErrorColor(errors?.addressType)}`} />{" "}
            </div> */}
            <div className="col-md-6  p-2">
              <label className="text-size">National Address </label>
              <input type="text" name="nationalAddress" value={returnData(data, "nationalAddress")} onChange={formChange("nationalAddress", "Alphabet")} placeholder=" National Address" className={`form-control p-2     ${returnErrorColor(errors?.nationalAddress)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">City </label>
              <input type="text" name="city" value={returnData(data, "city")} onChange={formChange("city", "Alphabet")} placeholder="City" className={`form-control p-2     ${returnErrorColor(errors?.city)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size">Cluster</label>
              <input type="text" name="cluster" value={returnData(data, "cluster")} onChange={handleChange("cluster")} placeholder="Cluster" className={`form-control p-2     ${returnErrorColor(errors?.cluster)}`} />{" "}
            </div>
            <div className="col-md-6  p-2">
              <label className="text-size"> Neighbourhood/District</label>
              <input type="text" name="neighbourhoodDistrict" value={returnData(data, "neighbourhoodDistrict")} onChange={formChange("neighbourhoodDistrict", "Alphabet")} placeholder=" Neighbourhood/District" className={`form-control p-2     ${returnErrorColor(errors?.neighbourhoodDistrict)}`} />{" "}
            </div>

            {/* <div className="col-md-6  p-2">
              <label className="text-size">Zip code </label>
              <input type="text" name="pinCode" value={returnData(data, "pinCode")} maxLength={5} onChange={handleChange("pinCode")} placeholder="Zip code" className={`form-control p-2     ${returnErrorColor(errors?.pinCode)}`} />{" "}
            </div> */}

          </div>
          <div className="col-md-12 col-lg-8 col-xl-8      mt-2   border-muted   " style={{ height: "91vh", overflowY: "scroll" }}>
            {data?.specialityId != undefined && services?.filter((v) => v?.specialityId == data?.specialityId)?.length > 0 ? (
              <>
                {services
                  ?.filter((v) => v?.specialityId == data?.specialityId)
                  ?.map((e, i) => {
                    return (
                      <div className=" d-flex  mx-2" style={{ maxHeight: "auto" }}>
                        <div
                          onClick={() => addObject({ serviceId: e?.serviceId })}
                          className=" w-100  justify-content-between my-1 p-4 rounded border border text-black pointer "
                          style={{
                            backgroundColor: data?.serviceId == e?.serviceId ? css.Booked : css.Preadmission,
                          }}
                        >
                          <div className="d-flex justify-content-between ">
                            <div className=" col-md-6 fs-8 fw-semibold" key={i}>
                              {e?.serviceName} [<small className="">{e?.specialityName}</small>]
                            </div>
                            <div className="col-md-6 text-end">
                              <small>
                                Consultation Fee: <span className="fs-8 fw-semibold"> {e?.charge || "0"}</span>
                              </small>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            {/* <div className="col-md-9 col-lg-9">{e?.description}</div> */}
                            <div className="col-md-3 col-lg-3  text-end">
                              {data?.serviceId == e?.serviceId && (
                                <div
                                  className=" text-center rounded p-2"
                                  style={{
                                    backgroundColor: data?.serviceId == e?.serviceId ? "#03469E" : css.Preadmission,
                                    color: "white",
                                  }}
                                  // onClick={() => {
                                  //   if (data?.paymentStatus == "Paid") {
                                  //     submit();
                                  //   } else {
                                  //     changeModel("payment");
                                  //   }
                                  // }}
                                  onClick={handleSubmit}
                                >
                                  Book Appointment
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <div className="steth2 d-flex  justify-content-center" style={{ height: "100%" }}></div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickAppointment;
