import instance from "../Services";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import botInstance from "../Services/BotIndex";
import QbotInstance from "../Services/QuobotoIndex";
import twilioInstance from "../Services/TwilioIndex";
import "../App.css";
import axios from "axios";
import SCInstance from "../Services/SCIndex";
import Swal from "sweetalert2";

export const notify = (status, msg) => {
  toast.configure();
  if (status == true) {
    toast.success(msg, { position: toast.POSITION.BOTTOM_CENTER });
  } else {
    toast.error(msg, { position: toast.POSITION.BOTTOM_CENTER });
  }
};

export const getList = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      if (res.data.data?.length == undefined) {
        return [];
      } else {
        return res.data.data;
      }
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const getfList = async (url) => {
  try {
    let res = await instance.get(url);
    if (res?.data?.status == true && res?.data?.data?.length != 0) {
      return res.data.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const save = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    notify(res?.data?.status, res?.data?.message);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const saveWithoutMessage = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    return res || {}
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const saveWithoutMessageWithoutLoad = async (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  };
  try {
    let res = await axios?.post(`${process.env.REACT_APP_API_URL}${url}`, data, { headers });
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};


export const getData = async (url, data) => {
  let headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.getItem("token"),
  };
  try {
    let res = await axios?.post(url, data, { headers });
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
};

export const dilogueBox = (text, onClickFn) => {
  Swal.fire({
    title: text,
    showCancelButton: true,
    confirmButtonText: 'Yes',
  }).then((result) => {

    if (result.isConfirmed) {
      onClickFn()
    }
  })
}
export const Add = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true) {
      return res;
    } else {
      notify(res?.data?.status, res?.data?.message);
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const botResponce = async (url, data) => {
  try {
    let res = await botInstance.post(url, data);
    return res;
  } catch (e) {
    console.log(e);
    // return {};
  }
};


export const getById = async (url, data) => {
  try {
    let res = await instance.post(url, data);
    if (res?.data?.status == true && Object.keys(res?.data?.data)?.length != 0) {
      return res?.data?.data;
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const twilioApi = async (url, data) => {
  try {
    let res = await twilioInstance.post(url, data);
    if (res?.data?.status == true) {
      return res;
    } else {
      return {};
    }
  } catch (e) {
    console.log(e);
    return {};
  }
};
export const postMethod = async (url, data) => {
  try {
    let res = await QbotInstance.post(url, data);
    if (res?.data?.status == true) {
      return res?.data?.data;
    } else {
      return "";
    }
  } catch (e) {
    console.log(e);
    return "";
  }
};

export const getMethod = async (url, data) => {
  try {
    let res = await QbotInstance.get(url);
    return res;
  } catch (e) {
    console.log(e);
    return {};
  }
};



export const SCResponce = async (url, data) => {
  try {
    let res = await SCInstance.post(url, data);
    return res;
  } catch (e) { console.log(e) }
};

export const SCGetResponce = async (url, data) => {
  try {
    let res = await SCInstance.get(url, data);
    return res;
  } catch (e) { console.log(e) }
};




